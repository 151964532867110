import { Translation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Ai } from './index';
import { Configuration } from './Configuration';
import { ConfigurationList } from './Configuration/ConfigurationList';
import { FallbackBehaviourList } from './Configuration/FallbackBehaviour/FallbackBehaviourList';
import { FallbackBehaviourNew } from './Configuration/FallbackBehaviour/FallbackBehaviourNew';
import { FallbackBehaviourEdit } from './Configuration/FallbackBehaviour/FallbackBehaviourEdit';

export const aiRoutes = () => ({
  path: Paths.ai(),
  element: <Ai />,
  breadcrumb: null,
  children: [
    { index: true, element: <Navigate replace to="configuration" />, breadcrumb: null },
    {
      path: Paths.aiConfiguration(),
      element: <Configuration />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('configuration')}</Translation>,
      children: [
        {
          index: true,
          element: <ConfigurationList />,
        },
        {
          path: Paths.aiFallbackBehaviour(),
          element: <FallbackBehaviourList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('fallback_behav')}</Translation>,
        },
        {
          path: Paths.aiFallbackBehaviourCreate(),
          element: <FallbackBehaviourNew />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiFallbackBehaviourEdit(),
          element: <FallbackBehaviourEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
      ],
    },
  ],
});
