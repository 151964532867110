import React, { FocusEvent, useRef, useState } from 'react';
import { Form, Input, Radio, Select, Popconfirm, Divider, Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formItemClass } from '../../../../../theme';
import { LocationSelect } from '../../LocationSelect';
import { hyphenateString } from '../../../../../utils/string';
import { getGCPKeyfile, getKeyfileProjectId, normalizeFile } from '../../../../../utils/files';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/chevron-double-right.svg';
import { LanguagesSearch } from '../../LanguagesSearch';
import { filterSelectOptions } from '../../../../../utils/filtering';
import useLanguages from '../../../../../hooks/useLanguages';
import { PasswordInput } from '../../../../../components/PasswordInput';
import { Space } from '../../../../../components/Space';
import { UploadButton } from '../../../../../components/UploadButton';

const { Option } = Select;

export const AIAutomationSettings: React.FC = (): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams();
  const { knowledgeWorkflow } = useFlags();
  const { languages } = useLanguages();
  const { validateFields, setFieldValue, getFieldValue } = Form.useFormInstance();
  const [popupVisible, setPopupVisible] = useState(false);
  const [keyfileProjectId, setKeyfileProjectId] = useState('');
  const projectNameTimeout = useRef<NodeJS.Timeout>();
  const isAIAutoSelected = getFieldValue('ai_n_a');

  const checkProjectName = async (projectName: string, keyfile: UploadFile[]) => {
    if (projectName && keyfile) {
      const result = await getGCPKeyfile(keyfile);
      const projectId = result && getKeyfileProjectId(result);
      if (projectId) {
        setPopupVisible(projectId !== projectName);
        setKeyfileProjectId(projectId);
      }
    }
  };

  const handleProjectIdBlur = (event: FocusEvent<HTMLInputElement>) =>
    setFieldValue(['ai_n_a_attributes', 'gcp_project_name'], hyphenateString(event.target.value));

  const handleProjectIdChange = () => {
    if (projectNameTimeout.current) {
      clearTimeout(projectNameTimeout.current);
    }
    projectNameTimeout.current = setTimeout(async () => {
      await checkProjectName(
        getFieldValue(['ai_n_a_attributes', 'gcp_project_name']),
        getFieldValue(['ai_n_a_attributes', 'gcp_keyfile']),
      );
    }, 1000);
  };

  const confirm = () => setPopupVisible(false);

  const handleCancel = () => {
    setFieldValue(['ai_n_a_attributes', 'gcp_project_name'], keyfileProjectId);
    setPopupVisible(false);
  };

  const handleKeyfileChange = async () => {
    try {
      await validateFields([['ai_n_a_attributes', 'gcp_keyfile']]);
    } catch {
      setKeyfileProjectId('');
      setPopupVisible(false);
    }
    await checkProjectName(
      getFieldValue(['ai_n_a_attributes', 'gcp_project_name']),
      getFieldValue(['ai_n_a_attributes', 'gcp_keyfile']),
    );
  };

  return (
    <>
      <Divider style={{ margin: '0 0 8px' }} />
      {!id && (
        <>
          <Form.Item name="nlu_provider" label={t('nlu_provider')} className={formItemClass.base}>
            <Select placeholder={t('nlu_provider')}>
              <Option value="google">Google DialogFlow</Option>
            </Select>
          </Form.Item>
          <Space>
            <div>
              <Form.Item
                name={['ai_n_a_attributes', 'display_name']}
                label={t('agent_name')}
                className={formItemClass.base}
                rules={[{ required: isAIAutoSelected }]}
              >
                <Input placeholder={t('agent_name')} />
              </Form.Item>
              <Form.Item
                name={['ai_n_a_attributes', 'gcp_keyfile']}
                label={t('gcp_keyfile')}
                tooltip={t('gcp_keyfile_tooltip')}
                rules={[{ required: isAIAutoSelected }]}
                valuePropName="fileList"
                getValueFromEvent={normalizeFile}
              >
                <UploadButton accept=".json" onChange={handleKeyfileChange} />
              </Form.Item>
            </div>
            <div>
              <Popconfirm
                title={t('project_id_error')}
                onConfirm={confirm}
                onCancel={handleCancel}
                placement="right"
                open={popupVisible}
                okText={t('yes')}
                okButtonProps={{ icon: <ArrowIcon width={14} height={14} /> }}
                cancelText={t('use_id_keyfile')}
                icon={<></>}
              >
                <Form.Item
                  name={['ai_n_a_attributes', 'gcp_project_name']}
                  label={t('gcp_project_id')}
                  className={formItemClass.base}
                  rules={[{ required: isAIAutoSelected }]}
                  tooltip={t('gcp_project_id_tooltip')}
                >
                  <Input
                    placeholder={t('gcp_project_id')}
                    onBlur={handleProjectIdBlur}
                    onChange={handleProjectIdChange}
                  />
                </Form.Item>
              </Popconfirm>
              <Form.Item
                name={['ai_n_a_attributes', 'gcp_fulfillment_auth_keyfile']}
                label={t('gcp_fulfillment_file')}
                tooltip={t('gcp_fulfillment_file_tooltip')}
                valuePropName="fileList"
                getValueFromEvent={normalizeFile}
              >
                <UploadButton accept=".json" />
              </Form.Item>
            </div>
          </Space>
          <Form.Item
            name="platform_edition"
            label={t('platform_edition')}
            className={formItemClass.base}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="es">ES</Radio.Button>
              <Radio.Button value="cx" disabled>
                CX
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.platform_edition !== currentValues.platform_edition
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('platform_edition') === 'cx' && (
                <Space>
                  <Form.Item
                    name="uuid"
                    label={t('uuid')}
                    className={formItemClass.base}
                    tooltip={t('uuid_tooltip')}
                  >
                    <Input placeholder={t('uuid')} />
                  </Form.Item>
                  <Form.Item
                    name="location"
                    label={t('location')}
                    tooltip={t('location_tooltip')}
                    className={formItemClass.base}
                  >
                    <LocationSelect placeholder={t('location')} />
                  </Form.Item>
                </Space>
              )
            }
          </Form.Item>
          <Divider />
          <Form.Item
            name="voice_provider"
            label={t('chat_voice_provider')}
            className={formItemClass.base}
          >
            <Select placeholder={t('chat_voice_provider')}>
              <Option value="twilio">Twilio</Option>
            </Select>
          </Form.Item>
          <Space>
            <Form.Item
              name={['ai_n_a_attributes', 'twilio_account_sid']}
              label={t('twilio_account_sid')}
              className={formItemClass.base}
              rules={[{ required: isAIAutoSelected }]}
              tooltip={t('twilio_account_sid_tooltip')}
            >
              <Input placeholder="ACxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" />
            </Form.Item>
            <Form.Item
              name={['ai_n_a_attributes', 'twilio_auth_token']}
              label={t('twilio_auth_token')}
              className={formItemClass.base}
              rules={[{ required: isAIAutoSelected }]}
              tooltip={t('twilio_auth_token_tooltip')}
            >
              <PasswordInput placeholder="*******************" />
            </Form.Item>
          </Space>
          <Form.Item
            name={['ai_n_a_attributes', 'twilio_phone_number']}
            label={t('twilio_phone_number')}
            className={formItemClass.base}
            rules={[{ required: isAIAutoSelected }]}
          >
            <Input placeholder="###-###-####" />
          </Form.Item>
          <Divider />
        </>
      )}
      <Space size={40}>
        <Form.Item
          name="default_language_id"
          label={t('default_language')}
          className={formItemClass.base}
          rules={[{ required: isAIAutoSelected }]}
        >
          <Select
            placeholder={t('default_language')}
            disabled={!!id}
            showSearch
            filterOption={filterSelectOptions}
          >
            {languages?.map(lng => (
              <Option key={lng.locale} value={lng.id.toString()}>
                {lng.name} ({lng.locale})
              </Option>
            ))}
          </Select>
        </Form.Item>
        {knowledgeWorkflow && (
          // remove the disabled below once the new project Edit is working on the BE
          <Form.Item
            name={['knowledge_v2_attributes', 'is_approval_flow_enabled']}
            label={t('workflow')}
            valuePropName="checked"
            tooltip={t('tooltip_workflow')}
          >
            <Switch disabled={!!id} />
          </Form.Item>
        )}
      </Space>
      <Form.Item
        label={t('additional_languages')}
        name="project_languages_attributes"
        style={{ marginBottom: 0 }}
      >
        <LanguagesSearch languages={languages} placeholder={t('search_language')} />
      </Form.Item>
    </>
  );
};
