import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Location } from 'history';
import { useWindowDimensions } from '../../../../utils/useWindowDimensions';
import { Content } from '../../../../components/Content';
import { ReportType, Reports } from '../types';
import { BreadCrumbsText, UppercaseLink } from '../../Analyics.styles';
import { OverlaySpinner } from '../../../../components/Spinner';
import { ReportsService } from '../../../../services/reports';

interface ILocation extends Location {
  state: { name: string; type: ReportType };
}

const consoleFlexApi = process.env.REACT_APP_CONSOLE_FLEX_API || '';

export const FAReportIframe: React.FC<any> = () => {
  const { t } = useTranslation('reports');
  const [currentUrl, setCurrentUrl] = useState<string>();
  const location = useLocation() as ILocation;
  const [isIframeLoading, setIsIframeLoading] = React.useState<boolean>(true);
  const { height } = useWindowDimensions();
  const { name, type } = location.state;
  const headerHeight = 185;
  const { isLoading, data } = useQuery<Reports[]>(
    [ReportsService.queryKeyFAReports],
    ({ signal }) => ReportsService.getFAReports(signal),
  );

  useEffect(() => {
    setIsIframeLoading(true);
    const currentUrl = consoleFlexApi + data?.find(e => e.name === name)?.url;
    setCurrentUrl(currentUrl);
  }, [name, data]);

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLink to={`/analytics/${type}`}> {t('reports')} | </UppercaseLink>
      <BreadCrumbsText>{` ${name}`} </BreadCrumbsText>
      <div style={{ position: 'relative' }}>
        <OverlaySpinner loading={isLoading || isIframeLoading} />
        {currentUrl && (
          <iframe
            title="faReport"
            width="100%"
            style={{ paddingTop: '20px', display: 'block' }}
            height={height - headerHeight}
            src={currentUrl}
            onLoad={() => setIsIframeLoading(false)}
          />
        )}
      </div>
    </Content>
  );
};
