import { useEffect } from 'react';
import { useIntegrations } from '../../../../state/integrations';
import { useAccount } from '../../../../state/account';
import { CONSOLE_CHAT_ID } from '../../../../constants';

export default function useChatScript(profileId?: number) {
  const { chat } = useIntegrations();
  const { activeProjectId, activeAccountId } = useAccount();

  useEffect(() => {
    let script: HTMLScriptElement, div: HTMLElement;
    if (profileId && chat) {
      div = document.createElement('div');
      div.id = CONSOLE_CHAT_ID;
      div.dataset.accountId = activeAccountId;
      div.dataset.projectId = chat.metadata.project_id.toString();
      div.dataset.projectConsoleId = activeProjectId;
      div.dataset.profileId = profileId.toString();
      script = document.createElement('script');
      script.src = chat.url;
      script.async = true;
      document.body.appendChild(div);
      document.body.appendChild(script);
    }

    return () => {
      if (script) document.body.removeChild(script);
      if (div) document.body.removeChild(div);
      localStorage.removeItem('CONSOLE_CHAT_IS_EXPANDED');
      localStorage.removeItem('CONSOLE_CHAT_WIDGET');
    };
  }, [profileId, chat]);
}
