import { Select, SelectProps } from 'antd';
import { currencies } from '../../constants';

const { Option } = Select;

interface Props extends SelectProps {
  isSymbol?: boolean;
}

export const CurrencySelector: React.FC<Props> = ({ isSymbol, ...other }) => {
  return (
    <Select {...other}>
      {currencies.map(item => (
        <Option key={item.code} value={item.code}>
          {isSymbol && `(${item.symbol})`} {item.code.toUpperCase()}
        </Option>
      ))}
    </Select>
  );
};
