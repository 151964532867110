import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { FixedNav } from '../../components/FixedNav';
import useSelectedMenuItem from '../../hooks/useSelectedMenuItem';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';

export const BillingMenu = (): JSX.Element => {
  const { t } = useTranslation('pages_title');

  const menuItems = [
    {
      key: '/billing/summary',
      icon: <ListIcon width={15} height={15} />,
      label: <Link to="/billing/summary">{t('summary')}</Link>,
    },
    {
      key: '/billing/details',
      icon: <EditIcon width={15} height={15} />,
      label: <Link to="/billing/details">{t('details')}</Link>,
    },
  ];

  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
