import { currencies, CurrencyCode, CurrencySymbol } from '../constants';
const toFixedX = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
};

export const getCurrencySymbol = (currency: CurrencyCode): CurrencySymbol =>
  currencies.find(item => item.code === currency)?.symbol as CurrencySymbol;

export const toLocalePrice = (price: number | string, locale: string): string =>
  Number(price).toLocaleString(locale, toFixedX);
