import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { billingQuery } from './api';
import { IBillingUrl } from '../state/integrations';
import { IBilling, IBillingBill } from '../pages/Billing/types';

const queryKey = 'billing';
const queryKeyBill = 'bill';
const queryKeyExpensesBills = 'expenses-bills';

const language = localStorage.getItem('i18nextLng');

const getProject = async (
  billingUrl: IBillingUrl,
  signal: QueryFunctionContext['signal'],
): Promise<IBilling> => {
  const { url, metadata } = billingUrl;
  return billingQuery(`${url}`, { headers: { ...metadata }, signal });
};

const getBills = async ({
  queryKey,
  signal,
}: QueryFunctionContext<[string, IBillingUrl]>): Promise<IBillingBill[]> => {
  const { url, metadata } = queryKey[1];
  return billingQuery(`${url}/bill`, { headers: { ...metadata }, signal });
};

const getExpensesBills = async (
  billingUrl: IBillingUrl,
  signal: QueryFunctionContext['signal'],
): Promise<IBillingBill[]> => {
  const { url, metadata } = billingUrl;
  const [expensesResult, billResult] = await Promise.all([
    billingQuery(`${url}/expenses`, { headers: { ...metadata }, signal }),
    billingQuery(`${url}/bill`, { headers: { ...metadata }, signal }),
  ]);

  return [expensesResult, ...billResult];
};

const getFile = async ({
  queryKey,
  signal,
}: QueryFunctionContext<[string, string, IBillingUrl]>): Promise<ArrayBuffer> => {
  const { metadata, url } = queryKey[2];
  return billingQuery(`${url}/bill/${queryKey[1]}/download?lang=${language}`, {
    headers: { ...metadata },
    responseType: 'blob',
    signal,
  });
};

export const BillingService = {
  queryKey,
  queryKeyBill,
  queryKeyExpensesBills,
  getProject,
  getBills,
  getExpensesBills,
  getFile,
};
