import React from 'react';
import { Translation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Details } from './Details';
import { Billing } from './index';
import { Summary } from './Summary';

export const billingRoutes = () => ({
  path: Paths.billing(),
  element: <Billing />,
  breadcrumb: null,
  children: [
    { index: true, element: <Navigate replace to={Paths.billingSummary()} />, breadcrumb: null },
    {
      path: Paths.billingSummary(),
      element: <Summary />,
      breadcrumb: () => <Translation ns={'pages_title'}>{t => t('summary')}</Translation>,
    },
    {
      path: Paths.billingDetails(),
      element: <Details />,
      breadcrumb: () => <Translation ns={'pages_title'}>{t => t('details')}</Translation>,
    },
  ],
});
