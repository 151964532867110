import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { equals } from 'ramda';
import { Space } from '../../../../components/Space';
import { FixedBottomBar } from '../../../../components/FixedBottomBar';
import { EmailsInput } from './EmailsInput';
import { validateMessages } from '../../../../utils/validation';
import { useAccount } from '../../../../state/account';
import { useAuth } from '../../../../state/auth';
import { UnsavedChangesModal } from '../../../../components/UnsavedChangesModal';
import { ReactComponent as PaperPlane } from '../../../../assets/icons/paper-plane.svg';
import { useGetItem } from '../../../../hooks/useGetItem';
import { ProjectsService } from '../../../../services/projects';
import { IProject } from '../../types';
import { Permissions } from './Permissions';
import { IUser } from '../UsersList';
import { ActionButton } from '../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../components/DiscardChangesModal';
import { useModal } from '../../../../hooks/useModal';
import { getName } from '../../../../utils/user';

export interface DataType {
  id: number;
  permission: string;
}

export interface IUsersValues {
  emails: string[];
  email?: string;
  selected_permissions?: DataType[];
}

export interface IUsersPayload {
  emails: string[];
  projectId: string;
  clientId: string;
  senderName: string;
  locale: string;
}

export interface IPermission {
  id: number;
  permission: string;
}

interface IUsers {
  initialValues?: IUsersValues;
  onSubmit: (values: IUsersPayload) => void;
  sendAnotherBtn?: boolean;
  setSendAnotherBtn?: any;
  setInviteBtn?: any;
  setInviteNewBtn?: any;
  isEdit?: boolean;
  user?: IUser;
}

export const UsersForm: React.FC<IUsers> = ({
  initialValues,
  onSubmit,
  sendAnotherBtn,
  setSendAnotherBtn,
  setInviteBtn,
  setInviteNewBtn,
  isEdit,
  user,
}) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation('project_settings');
  const navigate = useNavigate();
  const [selectedPermissionsData, setSelectedPermissionsData] = useState<DataType[]>([]);
  const { activeAccountId, activeProjectId } = useAccount();
  const { fullName } = useAuth();
  const { visible, openModal, closeModal } = useModal(false);
  const [isUnsavedChangesModal, setIsUnsavedChangesModal] = useState<boolean>(false);
  const { userPermissions } = useFlags();
  const { data: project } = useGetItem<IProject>(
    [ProjectsService.queryKey, activeAccountId, activeProjectId],
    ProjectsService.find,
    { enabled: !!activeAccountId && !!activeProjectId },
  );

  function onFinish(values: IUsersValues) {
    if (activeProjectId && activeAccountId) {
      const payload = {
        emails: values.emails,
        locale: i18n.language,
        projectId: activeProjectId,
        clientId: activeAccountId,
        senderName: fullName,
      };
      onSubmit(payload);

      if (sendAnotherBtn) {
        form.resetFields();
        setSelectedPermissionsData([]);
      }
    }
  }

  function handleCancel() {
    if (isEdit && !equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  }

  if (!project?.name) return null;

  return (
    <Space direction="vertical" size={!isEdit ? 'middle' : 0}>
      {!isEdit && (
        <p>
          {t('users_desc_start')} <strong>{project?.name}</strong> {t('users_desc_end')}
        </p>
      )}
      {user && (
        <p style={{ margin: 0 }}>
          {t('edit_users_desc_start')} “<strong>{getName(user.external_data)}</strong>”{' '}
          {t('edit_users_desc_end')} <strong>{project?.name}</strong>.
        </p>
      )}
      <Form
        initialValues={initialValues}
        onFinish={onFinish}
        form={form}
        layout="vertical"
        validateMessages={validateMessages}
      >
        {!isEdit && <EmailsInput />}
        {userPermissions && <Permissions selectedPermissions={selectedPermissionsData} />}

        <FixedBottomBar>
          <ActionButton type="cancel" onClick={handleCancel} />
          {isEdit ? (
            <>
              <Button
                icon={<PaperPlane width={15} height={15} />}
                onClick={() => setInviteNewBtn(true)}
                htmlType="submit"
              >
                {t('invite_new')}
              </Button>
              <ActionButton type="save" htmlType="submit" />
            </>
          ) : (
            <>
              <Button
                icon={<PaperPlane width={15} height={15} />}
                onClick={() => setSendAnotherBtn(true)}
                htmlType="submit"
              >
                {t('invite_another')}
              </Button>
              <Button
                icon={<PaperPlane width={15} height={15} />}
                onClick={() => setInviteBtn(true)}
                type="primary"
                htmlType="submit"
              >
                {t('invite')}
              </Button>
            </>
          )}
        </FixedBottomBar>
      </Form>
      <UnsavedChangesModal
        modalVisible={isUnsavedChangesModal}
        setIsUnsavedChangesModal={setIsUnsavedChangesModal}
        title={t('unsaved_changes')}
        description={t('unsaved_changes_description')}
        btnText={t('discard_changes')}
      />
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Space>
  );
};
