import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import {
  ReactComponent as Trash,
  ReactComponent as DeleteIcon,
} from '../../assets/icons/trash-alt.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/times.svg';
import { ActionButton } from '../ActionButton';
import { TableActionButton } from '../DataTable/DataTable.styles';

interface Props {
  id: string | number;
  title?: string;
  name?: string;
  children?: React.ReactNode;
  onActionDelete: (id: string | number) => void;
  disabled?: boolean;
  loading?: boolean;
  isTextButton?: boolean;
  modalIcon?: React.ReactNode;
  modalButtonText?: React.ReactNode;
}

export const DeleteConfirmation: React.FC<Props> = ({
  id,
  title,
  name,
  children,
  onActionDelete,
  disabled,
  loading,
  isTextButton,
  modalIcon,
  modalButtonText,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleOk = () => {
    setVisible(false);
    onActionDelete(id);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const renderDelete = () =>
    isTextButton ? (
      <Button
        type="link"
        size="small"
        danger
        icon={<DeleteIcon width={12} height={12} />}
        onClick={() => showModal()}
        disabled={disabled}
        loading={loading}
      >
        {t('delete')}
      </Button>
    ) : (
      <TableActionButton onClick={() => showModal()} disabled={disabled} loading={loading}>
        <TrashIcon width={13} />
      </TableActionButton>
    );

  return (
    <>
      {renderDelete()}
      <Modal
        title={
          <>
            {modalIcon || <DeleteIcon width={16} height={16} />}{' '}
            {title || t('delete').toUpperCase()}
          </>
        }
        centered
        open={visible}
        closeIcon={<CloseIcon width={16} height={16} />}
        className="modal-danger"
        footer={[
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />,
          modalIcon ? (
            <Button
              type={'primary'}
              danger
              key="delete"
              icon={modalIcon || <Trash width={13} height={14} />}
              onClick={handleOk}
            >
              {modalButtonText}
            </Button>
          ) : (
            <ActionButton type="delete" key="delete" onClick={handleOk} />
          ),
        ]}
        onCancel={handleCancel}
      >
        {children || (
          <p>
            <Trans>
              {name ? t('delete_confirmation_with_name', { name }) : t('delete_confirmation')}
            </Trans>
          </p>
        )}
      </Modal>
    </>
  );
};
