import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/es/interface';
import { App } from 'antd';
import { Content } from '../../../components/Content';
import { ActionButton } from '../../../components/ActionButton';
import { AccountsForm } from '../AccountsForm';
import { AccountsService } from '../../../services/client-account';
import { IAccount, IAccountsValues } from '../types';
import { useGetItem } from '../../../hooks/useGetItem';
import { useUpdate } from '../../../hooks/useUpdate';
import { formatApiErrors, getFormErrorPage, getFormErrors } from '../../../utils/errors';
import { accountStepsFields } from '../constants';
import { getFormErrorsConfig, unexpectedError } from '../../../utils/notification';
import { Paths } from '../../../types/paths';

export const AccountsEdit: React.FC = () => {
  const { t } = useTranslation('account_settings');
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const { data } = useGetItem<IAccount>([AccountsService.queryKey, id], AccountsService.find, {
    enabled: !!id,
    retry: 0,
    onError: (error: any) => {
      if (error.response?.status === 404) navigate('..');
    },
  });
  const [page, setPage] = useState<number>();
  const { mutate: updateItem, isLoading } = useUpdate<{
    id: string;
    values: IAccountsValues;
  }>([AccountsService.queryKey, id], AccountsService.update);

  const onSubmit = async (values: IAccountsValues, setFields: FormInstance['setFields']) => {
    setPage(undefined);
    await updateItem(
      { id, values },
      {
        onSuccess: data => {
          navigate(Paths.accounts());
          notification['success']({
            message: <Trans t={t}>{t('account_updated_notify', { name: data.name })}</Trans>,
          });
        },
        onError: (err: any) => {
          if (err?.response.status === 400) {
            const formattedErrors = formatApiErrors(err.response.data);
            const formStep = getFormErrorPage(accountStepsFields, formattedErrors);
            const formErrors = getFormErrors(formattedErrors);
            if (formStep || formStep === 0) {
              setPage(formStep);
              setTimeout(() => setFields(formErrors), 100);
            } else notification['error'](getFormErrorsConfig(formattedErrors));
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  if (!data) return <Content imgBg={true} />;
  if (!data.is_enabled) return <Navigate replace to=".." />;

  const initialValues: Partial<IAccountsValues> = {
    name: data.name,
    sales_confirmation_number: data.sales_confirmation_number,
    account_info: data.account_info,
    customer_info: data.customer_info,
    vendor_info: data.vendor_info,
  };

  return (
    <Content imgBg={true}>
      <AccountsForm
        initialValues={initialValues}
        isEdit={true}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isLoading} />}
        page={page}
      />
    </Content>
  );
};
