import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuth } from '../../state/auth';
import { initialLDContext, ACTIVE_ACCOUNT_NAME, ACTIVE_PROJECT_NAME } from '../../constants';

type Props = {
  activeAccountId?: string;
  activeProjectId?: string;
};

export default function useUpdateLDContext({ activeAccountId, activeProjectId }: Props): void {
  const ldClient = useLDClient();
  const { username, fullName } = useAuth();

  useEffect(() => {
    if (ldClient) {
      if (username.length) {
        ldClient.identify({
          ...ldClient.getContext(),
          user: {
            key: username,
            anonymous: false,
            name: fullName,
          },
        });
      } else {
        ldClient.identify(initialLDContext);
      }
    }
  }, [username, ldClient]);

  useEffect(() => {
    if (ldClient && activeAccountId) {
      ldClient.identify({
        ...ldClient.getContext(),
        account: {
          key: activeAccountId,
          anonymous: false,
          name: localStorage.getItem(ACTIVE_ACCOUNT_NAME),
        },
      });
    }
  }, [activeAccountId, ldClient]);

  useEffect(() => {
    if (ldClient && activeProjectId) {
      ldClient.identify({
        ...ldClient.getContext(),
        project: {
          key: activeProjectId,
          anonymous: false,
          name: localStorage.getItem(ACTIVE_PROJECT_NAME),
          account: {
            key: activeAccountId,
            name: localStorage.getItem(ACTIVE_ACCOUNT_NAME),
          },
        },
      });
    }
  }, [activeProjectId, ldClient]);
}
