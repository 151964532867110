import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { App } from './App';
import { CenteredSpinner } from './components/Spinner';
import './locales/i18n';
import { initialLDContext } from './constants';

(async () => {
  const clientid = process.env.REACT_APP_FEATURE_FLAG_CLIENTSIDE_ID;
  const LDProvider = await asyncWithLDProvider({
    // eslint-disable-next-line
    clientSideID: clientid!,
    context: initialLDContext,
  });
  const container = document.getElementById('root');

  if (container) {
    const root = createRoot(container);
    root.render(
      <LDProvider>
        <Suspense fallback={<CenteredSpinner />}>
          <App />
        </Suspense>
      </LDProvider>,
    );
  }
})();
