import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { FormInstance } from 'rc-field-form/es/interface';
import { App } from 'antd';
import { Content } from '../../../components/Content';
import { ActionButton } from '../../../components/ActionButton';
import { ProjectsForm } from '../ProjectsForm';
import { ProjectCreatedModal } from '../ProjectsForm/ProjectCreatedModal';
import { useAccount } from '../../../state/account';
import { useCreate } from '../../../hooks/useCreate';
import { ProjectsService } from '../../../services/projects';
import { IProjectsValues } from '../types';
import { projectStepsFields } from '../constants';
import { formatApiErrors, getFormErrorPage, getFormErrors } from '../../../utils/errors';
import { getFormErrorsConfig, unexpectedError } from '../../../utils/notification';
import { IProductIntegration } from '../../../state/integrations';
import { getPayload } from '../../../utils/project';

export const ProjectsNew: React.FC = () => {
  const { t, i18n } = useTranslation('project_settings');
  const { notification } = App.useApp();
  const [isProjectModal, setIsProjectModal] = useState(false);
  const { activeAccountId, setActiveProjectId } = useAccount();
  const [project, setProject] = useState<{ id: number; name: string }>();
  const [page, setPage] = useState<number>();
  const { mutate: createItem, isLoading } = useCreate(
    [ProjectsService.queryKey],
    ProjectsService.create,
  );
  const { data: integrations } = useQuery<IProductIntegration[], string>(
    [ProjectsService.queryKeyIntegrations, activeAccountId, project?.id, i18n.language],
    ProjectsService.getIntegrations,
    { enabled: !!project?.id },
  );

  useEffect(() => {
    if (integrations && project?.id) {
      setIsProjectModal(true);
      setActiveProjectId(project.id.toString());
    }
  }, [integrations, project]);

  const onSubmit = async (values: IProjectsValues, setFields: FormInstance['setFields']) => {
    const payload = await getPayload(values);
    setPage(undefined);

    createItem(
      { activeAccountId, data: payload },
      {
        onSuccess: data => setProject({ id: data.id, name: data.name }),
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(err.response.data);
            const formStep = getFormErrorPage(projectStepsFields, formattedErrors);
            const formErrors = getFormErrors(formattedErrors);
            if (formStep || formStep === 0) {
              setPage(formStep);
              setTimeout(() => setFields(formErrors), 100);
            } else {
              notification['error'](getFormErrorsConfig(formattedErrors));
            }
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  const initialValues: Partial<IProjectsValues> = {
    ai_n_a: true,
    nlu_provider: 'google',
    platform_edition: 'es',
    voice_provider: 'twilio',
    billing_attributes: {
      currency: 'gbp',
    },
    knowledge_v2_attributes: {
      is_approval_flow_enabled: false,
    },
  };

  return (
    <Content imgBg={false}>
      <p>{t('form_description')}</p>
      <ProjectsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
        page={page}
      />
      {!!project?.name && (
        <ProjectCreatedModal
          visible={isProjectModal}
          projectName={project.name}
          integrations={integrations}
        />
      )}
    </Content>
  );
};
