import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PaperPlaneIcon } from '../../../assets/icons/paper-plane.svg';
import { ErrorMessages, LoginFormErrors } from '../ErrorMessages';
import { StyledLoginForm } from '../Login.styles';
import { AuthService } from '../../../services/auth';
import { Locale } from '../../../locales/i18n.d';
import { FloatField } from '../../../components/FloatField';
import { Paths } from '../../../types/paths';

export type ForgotPasswordValues = {
  email: string;
};

export const ForgotPassword: React.FC<{ expired?: boolean }> = ({ expired }) => {
  const { t, i18n } = useTranslation('login');
  const navigate = useNavigate();
  const locale = i18n.language as Locale;
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<LoginFormErrors>({});

  const initialValues: ForgotPasswordValues = {
    email: '',
  };

  const validateMessages = {
    required: {
      title: t('enter_email'),
      body: t('email_reset_password'),
    } as any,
    types: {
      email: {
        title: t('invalid_email'),
        body: t('enter_valid_email'),
      } as any,
    },
  };

  async function onSubmit(values: ForgotPasswordValues) {
    await AuthService.forgotPassword({
      ...values,
      locale,
    });
    navigate(Paths.loginRecoverSuccess(), { replace: true });
  }

  return (
    <>
      <h2>{t('reset_password')}</h2>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onSubmit}
        validateMessages={validateMessages}
        validateTrigger="onBlur"
        requiredMark={false}
      >
        <StyledLoginForm>
          <div>
            <p>
              {expired && <strong>{t('reset_link_expired')} </strong>}
              {t('email_change_password')}
            </p>
            <FloatField
              name="email"
              label={t('label_email')}
              type="email"
              rules={[{ type: 'email', required: true }]}
              setErrors={setErrors}
            />
            <ErrorMessages errors={errors} />
          </div>
          <div>
            <ReturnLink onClick={() => navigate(Paths.login())}>{t('return_login')}</ReturnLink>
            <Button block htmlType="submit" type="primary">
              {t('send_email')}
              <PaperPlaneIcon width={14} height={14} />
            </Button>
          </div>
        </StyledLoginForm>
      </Form>
    </>
  );
};

const ReturnLink = styled.a`
  color: ${({ theme }) => theme.loginLinkColor};
  font-family: ${({ theme }) => theme.fontFamilyAccent};
  text-transform: uppercase;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.loginLinkColor};
  }

  & + button:not(style) {
    margin-top: 10px;
  }
`;
