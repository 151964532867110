import { theme } from '../theme';

export const chartGraph = {
  xField: 'expenses',
  yField: 'consumption',
  height: 600,
  color: theme.linkColor,
  maxColumnWidth: 60,
  interactions: [{ type: 'element-active' }],
  state: {
    active: {
      style: {
        fill: theme.primaryColor,
        stroke: theme.primaryColor,
      },
    },
  },
};
