import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import useSelectedMenuItem from '../../../hooks/useSelectedMenuItem';
import { FixedNav } from '../../../components/FixedNav';
import { ReactComponent as ChartIcon } from '../../../assets/icons/chart-bar.svg';

export const WellbeingNav: React.FC = () => {
  const { t } = useTranslation('reports');

  const menuItems = [
    {
      key: '/wellbeing',
      icon: <ChartIcon width={15} height={15} />,
      label: <Link to="/wellbeing">{t('dashboards')}</Link>,
    },
  ];
  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
