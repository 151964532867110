import styled from '@emotion/styled';
import { Col } from 'antd';

export const FlexCol = styled(Col)`
  display: flex;
  @media (max-width: 1024px) {
    flex: 100%;
    max-width: 100%;
    .ant-card-head-title {
      font-size: 20px;
    }
  }
  .ant-card {
    width: 100%;
  }
  .price {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 0.8em;
  }
`;
