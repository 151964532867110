import React from 'react';
import { Translation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Analytics } from './index';
import { DashboardsList } from './Reports/Dashboards/DashboardsList';
import { LooksList } from './Reports/Looks/LooksList';
import { ExploreList } from './Reports/Explore/ExploreList';
import { FAReportsList } from './Reports/FAReports/FAReportsList';
import { LookerIframe } from './Reports/LookerIframe';
import { FAReportIframe } from './Reports/FAReports/FAReportIframe';
export const analyticRoutes = () => ({
  path: Paths.analytics(),
  element: <Analytics />,
  breadcrumb: null,
  children: [
    {
      index: true,
      element: <Navigate replace to={Paths.analyticDashboards()} />,
      breadcrumb: null,
    },
    {
      path: Paths.analyticDashboards(),
      element: <DashboardsList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('dashboards')}</Translation>,
    },
    {
      path: Paths.analyticLooks(),
      element: <LooksList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('looks')}</Translation>,
    },
    {
      path: Paths.analyticExplore(),
      element: <ExploreList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('explore')}</Translation>,
    },
    {
      path: Paths.analyticFAReports(),
      element: <FAReportsList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('reports')}</Translation>,
    },
    { path: Paths.analyticDashboardView(), element: <LookerIframe /> },
    { path: Paths.analyticLookView(), element: <LookerIframe /> },
    { path: Paths.analyticExploreView(), element: <LookerIframe /> },
    { path: Paths.analyticFAReportView(), element: <FAReportIframe /> },
  ],
});
