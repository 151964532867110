import { useEffect, useState } from 'react';
import { Row, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Locale } from '../../../locales/i18n.d';
import { Content } from '../../../components/Content';
import { IBillingUrl, useIntegrations } from '../../../state/integrations';
import { BillingService } from '../../../services/billing';
import { groupBillingByGroup, getExpensesByItem } from '../../../utils/billing';
import { getCurrencySymbol, toLocalePrice } from '../../../utils/currency';
import { FlexCol } from '../../../components/FlexCol';
import { IBilling, IBillingBill, IExpensesByAgentGroup, IExpensesByItem } from '../types';
import { ExpensesChart } from './ExpensesChart';
import { ExpensesSummary } from './ExpensesSummary';
import { CurrencySymbol, initialCurrency } from '../../../constants';

type Expenses = {
  expensesByItem: IExpensesByItem[];
  expensesTotal: number;
};

const initialExpenses = { expensesByItem: [], expensesTotal: 0 };

export const Summary: React.FC = () => {
  const { t, i18n } = useTranslation('billing');
  const locale = i18n.language as Locale;
  const { billing } = useIntegrations();
  const [{ expensesByItem, expensesTotal }, setExpenses] = useState<Expenses>(initialExpenses);
  const [expensesByAgentGroup, setExpensesByAgentGroup] = useState<IExpensesByAgentGroup[]>([]);
  const [currency, setCurrency] = useState<CurrencySymbol>(initialCurrency);
  const { data, isLoading } = useQuery<IBilling>(
    [BillingService.queryKey, billing?.url],
    ({ signal }) => BillingService.getProject(billing as IBillingUrl, signal),
    { enabled: !!billing?.url },
  );
  const { data: expensesBills } = useQuery<IBillingBill[]>(
    [BillingService.queryKeyExpensesBills, billing?.url],
    ({ signal }) => BillingService.getExpensesBills(billing as IBillingUrl, signal),
    { enabled: !!billing?.url },
  );
  const currentConsumption = expensesBills?.[0]?.total.amount || 0;
  const lastBill = expensesBills?.[1]?.total.amount || 0;

  useEffect(() => {
    if (data) {
      setCurrency(getCurrencySymbol(data.currency));
    }
    if (!data?.agents[0].items.length && !isLoading) {
      setExpenses(initialExpenses);
      setExpensesByAgentGroup([]);
      setCurrency(initialCurrency);
    }
  }, [data]);

  useEffect(() => {
    if (expensesBills && !isLoading) {
      const agentItems = data?.agents[0].items.map((item, i) => {
        return {
          ...item,
          pricing: {
            ...item.pricing,
            price: {
              ...item.pricing.price,
              amount: expensesBills[0]?.agents[0]?.expenses[i]?.consumed.amount || 0,
            },
          },
        };
      });

      if (agentItems?.length) {
        const expensesByItem = getExpensesByItem(agentItems);
        setExpenses({
          expensesByItem,
          expensesTotal: expensesBills
            .map(item => Number(item.total.amount))
            .reduce((a, b) => a + b, 0),
        });
        setExpensesByAgentGroup(groupBillingByGroup(agentItems));
      }
    }
  }, [expensesBills?.length, data]);

  return (
    <Content imgBg={false}>
      <Row gutter={[16, 0]}>
        <FlexCol span={8}>
          <Card title={t('total_consumption')} bordered={false} size="small">
            <div className="price">
              {currency} {toLocalePrice(expensesTotal, locale)}
            </div>
            {t('started_since')} <b>{data && new Date(data.creation_date).toLocaleDateString()}</b>
          </Card>
        </FlexCol>
        <FlexCol span={16}>
          <Card title={t('current_consumption')} bordered={false} size="small">
            <div className="price">
              {currency} {toLocalePrice(currentConsumption, locale)}
            </div>
            {t('started_since')} <b>{data && new Date(data.last_bill).toLocaleDateString()}</b>
          </Card>
        </FlexCol>
      </Row>
      <Row gutter={[16, 0]}>
        <FlexCol span={8}>
          <Card title={t('expenses_summary')} bordered={false} size="small">
            {!!expensesBills && (
              <ExpensesSummary
                currency={currency}
                lastBill={lastBill}
                current={currentConsumption}
              />
            )}
          </Card>
        </FlexCol>
        <FlexCol span={8}>
          <Card title={t('expenses_by_group')} bordered={false} size="small">
            {expensesByAgentGroup && (
              <ExpensesChart data={expensesByAgentGroup} currency={currency} />
            )}
          </Card>
        </FlexCol>
        <FlexCol span={8}>
          <Card title={t('expenses_by_item')} bordered={false} size="small">
            {expensesByItem && <ExpensesChart data={expensesByItem} currency={currency} />}
          </Card>
        </FlexCol>
      </Row>
    </Content>
  );
};
