import styled from '@emotion/styled';
import { Card } from 'antd';
import { StyledViewLink } from '../../components/ViewLink/ViewLink.styles';

export const StyledCard = styled(Card)`
  max-width: 420px;
  padding: 24px 0 16px;
  .ant-card-head {
    min-height: 0;
  }
  .ant-card-head-wrapper {
    margin-bottom: 16px;
  }
  .ant-card-head-title {
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
  }
  .ant-card-body {
    padding: 0 24px;
  }
  ${StyledViewLink} {
    margin-top: 28px;
  }
`;

export const CardInfo = styled.div`
  min-height: 60px;
`;
