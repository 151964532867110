import React from 'react';
import { Button, Upload, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';

export const UploadButton: React.FC<UploadProps> = (props): JSX.Element => {
  const { maxCount = 1, beforeUpload } = props;
  const { t } = useTranslation();

  return (
    <Upload
      maxCount={maxCount}
      beforeUpload={beforeUpload ? beforeUpload : () => false}
      showUploadList={{
        removeIcon: <TrashIcon width={12} height={20} />,
      }}
      {...props}
    >
      <Button icon={<UploadIcon width={12} height={12} />}>{t('upload_file')}</Button>
    </Upload>
  );
};
