import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { integrationQuery, fAReportsQuery, AiIntegrationParams } from './api';
import { Reports } from '../pages/Analytics/Reports/types';
import { ApiResponse } from './types';
import { IAiIntegrationUrl } from '../state/integrations';

type DeleteProps = {
  id: string;
  aiIntegration: IAiIntegrationUrl;
};

const queryKeyDashboards = 'reportDashboards';
const queryKeyExplores = 'reportExplores';
const queryKeyLooks = 'reportLooks';
const queryKeyFAReports = 'fAReports';
const url = '/api/looker/embed';

const getDashboards = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
) => {
  const response = await integrationQuery(`${url}/dashboards`, { apiData, signal });
  return response || [];
};

const deleteDashboard = async ({ id, aiIntegration }: DeleteProps): Promise<ApiResponse> =>
  await integrationQuery(`${url}/dashboards/${id}`, {
    method: 'DELETE',
    apiData: aiIntegration,
  });

const getExplores = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<Reports[]> => {
  const response = await integrationQuery(`${url}/explores`, { apiData, signal });
  return (
    response.map((el: any) => ({
      id: el.name, // we need id to render element's list
      name: el.name,
      url: el.url,
    })) || []
  );
};

const getLooks = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<Reports[]> => {
  const response = await integrationQuery(`${url}/looks`, { apiData, signal });
  return response || [];
};

const getFAReports = async (signal: QueryFunctionContext['signal']): Promise<Reports[]> => {
  const response = await fAReportsQuery({ signal });
  return response.Reports || [];
};

const deleteLook = async ({ id, aiIntegration }: DeleteProps): Promise<ApiResponse> =>
  await integrationQuery(`${url}/looks/${id}`, {
    method: 'DELETE',
    apiData: aiIntegration,
  });

export const ReportsService = {
  queryKeyDashboards,
  queryKeyExplores,
  queryKeyLooks,
  queryKeyFAReports,
  getDashboards,
  getExplores,
  getLooks,
  getFAReports,
  deleteDashboard,
  deleteLook,
};
