import React, { useEffect, useState } from 'react';
import { Row, Card, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { DetailsTable } from './DetailsTable';
import { Content } from '../../../components/Content';
import { FlexCol } from '../../../components/FlexCol';
import { BillingService } from '../../../services/billing';
import { AccountsService } from '../../../services/client-account';
import { useGetItem } from '../../../hooks/useGetItem';
import { IBillingUrl, useIntegrations } from '../../../state/integrations';
import { useAccount } from '../../../state/account';
import { getCurrencySymbol } from '../../../utils/currency';
import { days, CurrencySymbol } from '../../../constants';
import { IBillAgentExpenses, IAccountDetails, IBillingBill, ICostData } from '../types';
import { IAccount } from '../../Accounts/types';
import { CostBreakdown } from '../CostBreakdown';
import { TruncatedString } from '../../../components/TruncatedString';

export const Details: React.FC = () => {
  const { t } = useTranslation('billing');
  const { billing } = useIntegrations();
  const { activeAccountId } = useAccount();
  const [agentExpenses, setAgentExpenses] = useState<IBillAgentExpenses[]>([]);
  const [creationDate, setCreationDate] = useState<string>('');
  const [currency, setCurrency] = useState<CurrencySymbol>('$');
  const [costData, setCostData] = useState<ICostData[]>([]);
  const [biggestExpense, setBiggestExpense] = useState<number>(0);
  const graphDataLimit = 12;
  const { data } = useQuery<IBillingBill[]>(
    [BillingService.queryKeyExpensesBills, billing?.url],
    ({ signal }) => BillingService.getExpensesBills(billing as IBillingUrl, signal),
    { enabled: !!billing?.url },
  );
  const { data: account } = useGetItem<IAccount>(
    [AccountsService.queryKey, activeAccountId],
    AccountsService.find,
    { enabled: !!activeAccountId, retry: 0 },
  );

  useEffect(() => {
    if (data?.length) {
      setAgentExpenses(data[0].agents?.[0].expenses);
      setCreationDate(new Date(data[0].end_date).toLocaleDateString());
      setCurrency(getCurrencySymbol(data[0].total.currency));
      setCostData(
        data
          .slice(0, graphDataLimit)
          .reverse()
          .map(item => ({
            expenses: new Date(item.end_date).toLocaleDateString(),
            consumption: item.total.amount,
          })),
      );
      setBiggestExpense(Math.max(...data.map(amount => amount.total.amount)));
    } else {
      setAgentExpenses([]);
      setCreationDate('-');
      setCurrency(getCurrencySymbol('usd'));
      setCostData([]);
      setBiggestExpense(0);
    }
  }, [data]);

  const accountDetails =
    account &&
    [
      {
        name: t('vat_number'),
        value: account.customer_info.vat_percent,
      },
      {
        name: t('email'),
        value: account.customer_info.email,
      },
      {
        name: t('phone'),
        value: account.customer_info.contact_number,
      },
      {
        name: t('address'),
        value: account.customer_info.address,
      },
      {
        name: t('postal_code'),
        value: account.customer_info.post_code,
      },
      {
        name: t('city'),
        value: account.customer_info.town,
      },
      {
        name: t('country'),
        value: account.customer_info.country,
      },
    ].map((item: IAccountDetails) => (
      <Row key={item.name}>
        <Col span={8}>{item.name}:</Col>
        <Col span={16}>
          <TruncatedString isShowFullOnHover={true}>{item.value}</TruncatedString>
        </Col>
      </Row>
    ));

  return (
    <Content imgBg={false}>
      <Row gutter={[16, 0]}>
        <FlexCol span={8}>
          <Card title={account?.name} bordered={false} size="small">
            {data && account && (
              <>
                {accountDetails}
                <Divider />
                <div className="ant-card-title">{creationDate}</div>
                {t('last_bill')}
                <Divider />
                <div className="ant-card-title">
                  {`${account?.account_info.billing_time_unit} (${t('start_on')} `}
                  {Object.values(days)[Number(account?.account_info.billing_time_amount) - 1]}
                  {')'}
                </div>
                {t('billing_cycle')}
              </>
            )}
          </Card>
        </FlexCol>
        <FlexCol span={16}>
          <CostBreakdown
            timeUnit={account?.account_info.billing_time_unit}
            costData={costData}
            biggestExpense={biggestExpense}
            currency={currency}
            creationDate={creationDate}
          />
        </FlexCol>
      </Row>
      <Row gutter={[16, 0]}>
        <FlexCol span={24}>
          <Card title={t('item_details')} bordered={false} size="small">
            {!!agentExpenses.length && (
              <DetailsTable agentExpenses={agentExpenses} currency={currency} />
            )}
          </Card>
        </FlexCol>
      </Row>
    </Content>
  );
};
