import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import backgroundImg from '../../assets/images/home-icon.png';
import { Breadcrumbs } from '../Breadcrumbs';

interface IContent {
  children?: React.ReactNode;
  imgBg?: boolean;
  Actions?: React.ReactNode[];
  header?: boolean;
}

export const Content = ({
  children,
  imgBg = true,
  Actions,
  header = true,
}: IContent): JSX.Element => {
  return (
    <StyledContent imgBg={imgBg} header={header}>
      {header && (
        <Header>
          <Breadcrumbs />
          {Actions && (
            <ActionsBar>
              {Actions.map((Action, i) => (
                <div key={i}>{Action}</div>
              ))}
            </ActionsBar>
          )}
        </Header>
      )}
      {children}
    </StyledContent>
  );
};

export const StyledContent = styled.div<{ imgBg: boolean; header?: boolean }>`
  position: relative;
  flex: 1 0 auto;
  padding: ${({ header }) => (header ? '20px 40px 40px' : '29px 40px 40px')};
  background-color: ${({ theme }) => theme.bgColor};
  z-index: 1;
  @media (max-width: 1024px) {
    padding: 20px 15px 40px;
  }
  ${({ imgBg, theme }) =>
    imgBg &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${backgroundImg});
        background-repeat: no-repeat;
        background-size: auto calc((100% - ${theme.headerHeight}) * 0.8);
        background-position: right calc((100% + ${theme.headerHeight} * 0.66) * 0.6);
        background-attachment: fixed;
        z-index: -1;
        opacity: 0.3;
      }
    `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ActionsBar = styled.div`
  display: flex;
  align-items: center;
`;
