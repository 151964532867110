import React from 'react';
import { Card, Empty } from 'antd';
import { Column, ColumnConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../../locales/i18n.d';
import { TitleComponent } from '../Details/TitleComponent';
import { billingPeriod, CurrencySymbol, Period } from '../../../constants';
import { ICostData } from '../types';
import { addFutureDate, getDateFormat } from '../../../utils/date';
import { chartGraph } from '../../../utils/chartGraph';
import { theme } from '../../../theme';
import { toLocalePrice } from '../../../utils/currency';

interface Props {
  creationDate: string;
  timeUnit?: Period;
  costData: ICostData[];
  biggestExpense: number;
  currency: CurrencySymbol;
}

export const CostBreakdown: React.FC<Props> = React.memo(
  ({ timeUnit, costData, creationDate, biggestExpense, currency }) => {
    const { t, i18n } = useTranslation('billing');
    const locale = i18n.language as Locale;
    const graphDataLimit = 12;
    const futureDate =
      costData?.length && costData.length < graphDataLimit
        ? [
            ...Array(graphDataLimit + 1 - costData.length)
              .fill(0)
              .map((item, i) => ({
                expenses: timeUnit && addFutureDate(creationDate, i, timeUnit),
                consumption: biggestExpense,
              }))
              .slice(1),
          ]
        : [];

    const summaryConfig: ColumnConfig = {
      data: [...costData, ...futureDate],
      ...chartGraph,
      height: 330,
      color: ({ expenses }) => {
        return expenses && getDateFormat(creationDate) < getDateFormat(expenses)
          ? theme.futureColumn
          : theme.linkColor;
      },
      meta: {
        consumption: {
          formatter: v => `${currency} ${toLocalePrice(v, locale)}`,
        },
        expenses: {
          formatter: v =>
            `${getDateFormat(v).toLocaleDateString(locale, {
              month: 'short',
              day: 'numeric',
            })}`,
        },
      },
    };

    return (
      <Card
        title={
          timeUnit && (
            <TitleComponent title={t('cost_breakdown')} Component={billingPeriod[timeUnit]} />
          )
        }
        bordered={false}
        size="small"
      >
        {costData.length ? (
          <Column {...summaryConfig} tooltip={false} />
        ) : (
          <Empty className="ant-empty-graph" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Card>
    );
  },
);
CostBreakdown.displayName = 'CostBreakdown';
