import { Row, Tooltip } from 'antd';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question-circle.svg';
import { IProductFeature } from '../../pages/Projects/types';

export const TitleWithToolTip = ({
  title,
  tooltip,
}: {
  title: IProductFeature['label'];
  tooltip: string;
}): JSX.Element => (
  <Row align="middle">
    <div>{title}</div>
    <Tooltip title={tooltip}>
      <QuestionIcon width={14} height={14} color="#bdbdc1" style={{ marginLeft: 4 }} />
    </Tooltip>
  </Row>
);
