import axios, { AxiosRequestConfig } from 'axios';
import { getAuthConfig } from '../utils/auth';
import { WithRequired } from '../types';
import { IAiIntegrationUrl } from '../state/integrations';

const authMiddlewareEndpoint = process.env.REACT_APP_API_ENDPOINT;
const consoleManagerEndpoint = process.env.REACT_APP_CONSOLE_MANAGER_API_ENDPOINT;
const faReportsDataFileUrl = process.env.REACT_APP_API_FA_DATA_FILE_URL;

export type QueryOptions<D = any> = Omit<AxiosRequestConfig<D>, 'url'>;

export const authMiddlewareQuery = async (
  url: string,
  options: QueryOptions = {},
): Promise<any> => {
  const authConfig = getAuthConfig();
  const { method = 'GET', data = null, ...other } = options;
  const headers = options.headers || {
    Accept: 'text/plain',
    'Content-Type': 'application/json-patch+json',
  };

  Object.assign(headers, {
    ...(authConfig?.access_token && {
      Authorization: authConfig.access_token,
    }),
  });

  const config = {
    url: authMiddlewareEndpoint + url,
    method,
    headers,
    data,
    ...other,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    return formatApiErrors(e);
  }
};

export type AiIntegrationParams = { apiData: IAiIntegrationUrl };
export const integrationQuery: (
  url: string,
  options: QueryOptions & { apiData: IAiIntegrationUrl },
) => Promise<any> = async (url, options) => {
  const { PROJECT_ID, AUTH_PROJECT_LIST, AUTH_TOKEN } = options.apiData.metadata;
  const { method = 'GET', data = null, ...other } = options;
  const headers = options.headers || {
    Accept: 'text/plain',
    'Content-Type': 'application/json-patch+json',
  };
  Object.assign(headers, { PROJECT_ID, AUTH_TOKEN, AUTH_PROJECT_LIST });

  const config = {
    url: options.apiData.url + url,
    method,
    headers,
    data,
    ...other,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const fAReportsQuery: (options?: QueryOptions) => Promise<any> = async (
  options: QueryOptions = {},
): Promise<any> => {
  const { method = 'GET', ...other } = options;
  const headers = {
    Accept: 'application/json',
  };

  const config = {
    url: faReportsDataFileUrl,
    method,
    headers,
    ...other,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};
export const consoleManagerQuery = async (
  url: string,
  options: QueryOptions = {},
): Promise<any> => {
  const authConfig = getAuthConfig();
  const { method = 'GET', data = null, ...other } = options;
  const headers = options.headers || {
    Accept: 'application/json',
  };
  Object.assign(headers, {
    ...(authConfig?.access_token && {
      AUTH_TOKEN: authConfig.access_token,
    }),
  });
  const config = {
    url: consoleManagerEndpoint + url,
    method,
    headers,
    data,
    ...other,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const knowledgeQuery: (
  url: string,
  options: WithRequired<
    QueryOptions<{
      externalId: string;
      url: string;
      metadata: {
        AUTH_TOKEN: string;
        AUTH_PROJECT_LIST: string;
        PROJECT_ID: string;
      };
      payload?: any;
    }>,
    'data'
  >,
) => Promise<any> = async (url, options) => {
  const { method = 'GET', data = null, ...other } = options;
  const headers = {
    ContentType: 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
    ...options.headers,
  };
  Object.assign(headers, {
    ...options.data.metadata,
  });
  const URL = url === '/channels' ? url : `/projects/${options.data.externalId}${url}`;
  const config = {
    url: `${options.data.url}/api/v1/admin${URL}`,
    method,
    headers,
    data: data?.payload,
    ...other,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const billingQuery = async (url: string, options: QueryOptions = {}): Promise<any> => {
  const { method = 'GET', data = null, ...others } = options;
  const config = {
    url,
    method,
    data,
    ...others,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

const formatApiErrors = (error: any) => ({
  error: {
    status: error.response?.status,
    data: error.response?.data,
    message: error.message,
  },
});
