import { theme } from '../theme';

export const chartDonut = {
  appendPadding: [5, 5],
  angleField: 'consumption',
  colorField: 'type',
  innerRadius: 0.6,
  label: {
    content: '',
  },
  statistic: {
    content: {
      style: {
        color: theme.primaryColor,
      },
    },
  },
  interactions: [
    {
      type: 'element-selected',
    },
    {
      type: 'element-active',
    },
  ],
  state: {
    active: {
      style: {
        stroke: theme.primaryColor,
        lineWidth: 2,
      },
    },
  },
};
