import { theme } from '../theme';
import { IBillingAgentItems, IExpensesByAgentGroup, IExpensesByItem } from '../pages/Billing/types';
import { billingOrderedExpenses } from '../constants';
import { toLocalePrice } from './currency';

export const expensesColors = [
  theme.secondaryBg,
  theme.greenColor,
  theme.yellowColor,
  theme.lightBlue,
  theme.goldGraph,
  theme.purpleColor,
  theme.mistyGraph,
  theme.blackGraph,
  theme.greenGraph,
  theme.redCollor,
  theme.grayCollor,
  theme.brownGraph,
  theme.primaryColor,
  theme.linkColor,
  theme.orangeColor,
  theme.lightGreen,
];

export const getExpenseColor = (i: number, name: string): string => {
  switch (name) {
    case 'Console':
    case 'Console Platform':
    case 'Sabio':
      return theme.primaryColor;
    case 'Support Services':
      return theme.linkColor;
    case 'Optimisation Services':
      return theme.orangeColor;
    default:
      return expensesColors[i] ? expensesColors[i] : '#' + Math.floor(Math.random() * 256);
  }
};

export const groupBillingByGroup = (billItems: IBillingAgentItems[]): IExpensesByAgentGroup[] => {
  const groupedBilling = billItems?.reduce(
    (entryMap, e: IBillingAgentItems) =>
      entryMap.set(e.group, [...(entryMap.get(e.group) || []), e]),
    new Map(),
  );

  return groupedBilling
    ? Array.from(groupedBilling).map((item, i) => ({
        id: item[0],
        type: item[0],
        color: getExpenseColor(i, item[0]),
        consumption: item[1]
          .map((el: IBillingAgentItems) => Number(el.pricing.price.amount))
          .reduce((a: number, b: number) => a + b, 0),
      }))
    : [];
};

export const getExpensesByItem = (data: IBillingAgentItems[]): IExpensesByItem[] =>
  data
    .map((item: IBillingAgentItems, i) => ({
      ...item,
      id: item.name,
      type: item.name,
      consumption: Number(item.pricing.price.amount),
      color: getExpenseColor(i, item.name),
    }))
    .sort(
      (a, b) => billingOrderedExpenses.indexOf(a.name) - billingOrderedExpenses.indexOf(b.name),
    );

export const getChartMeta = (currency: string, locale: string): Record<string, any> => ({
  consumption: {
    formatter: (v: string): string => `${currency} ${toLocalePrice(v, locale)}`,
  },
});
