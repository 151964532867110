import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StyledNavbar } from './Navbar.styles';
import { AccountsMenu } from './AccountsMenu';
import { ReactComponent as LightIcon } from '../../assets/icons/light.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/icons/integration.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/analytics.svg';
import { ReactComponent as UsdIcon } from '../../assets/icons/usd-circle.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question.svg';
import { ReactComponent as PaintIcon } from '../../assets/icons/paint-brush.svg';
import { ReactComponent as WellbeingIcon } from '../../assets/icons/wellbeing.svg';
import { ReactComponent as PulseIcon } from '../../assets/icons/pulse.svg';
import { ReactComponent as AiIcon } from '../../assets/icons/ai.svg';

export interface Props {
  modalToggle: () => void;
}

export const Navbar: React.FC<Props> = ({ modalToggle }) => {
  const { t } = useTranslation('pages_title');
  const {
    wellbeing,
    billing,
    newKnowledge,
    newDesign,
    pulse,
    analytics,
    legacyKnowledge,
    integration,
    legacyDesign,
    ai,
  } = useFlags();

  return (
    <StyledNavbar.Root>
      <AccountsMenu />
      <StyledNavbar.Nav>
        {/* <StyledNavbar.NavItem to="/">
          <ChartIcon width={22} height={22} />           
          {t('dashboard')}
        </StyledNavbar.NavItem> */}
        {analytics && (
          <StyledNavbar.NavItem to="analytics">
            <AnalyticsIcon width={28} height={22} />
            {t('analytics')}
          </StyledNavbar.NavItem>
        )}
        {ai && (
          <StyledNavbar.NavItem to="ai">
            <AiIcon width={32} height={32} />
            {t('ai')}
          </StyledNavbar.NavItem>
        )}
        {legacyKnowledge && (
          <StyledNavbar.NavItem to="knowledge">
            <LightIcon width={26} height={26} />
            {t('knowledge')}
          </StyledNavbar.NavItem>
        )}
        {integration && (
          <StyledNavbar.NavItem to="integration">
            <IntegrationIcon width={22} height={22} />
            {t('integration')}
          </StyledNavbar.NavItem>
        )}
        {legacyDesign && (
          <StyledNavbar.NavItem to="design">
            <PaintIcon width={22} height={22} />
            {t('design')}
          </StyledNavbar.NavItem>
        )}
        {wellbeing && (
          <StyledNavbar.NavItem to="wellbeing">
            <WellbeingIcon width={22} height={22} /> {t('wellbeing')}
          </StyledNavbar.NavItem>
        )}
        {billing && (
          <StyledNavbar.NavItem to="billing">
            <UsdIcon width={22} height={22} /> {t('billing')}
          </StyledNavbar.NavItem>
        )}
        {pulse && (
          <StyledNavbar.NavItem to="pulse">
            <PulseIcon width={22} height={22} /> {t('pulse')}
          </StyledNavbar.NavItem>
        )}
        {newKnowledge && (
          <StyledNavbar.TempNavItem to="new-knowledge">
            <StyledNavbar.NewLabel>NEW</StyledNavbar.NewLabel>
            <LightIcon width={22} height={22} /> {t('knowledge')}
            <p style={{ fontSize: 7 }}>({t('internal')})</p>
          </StyledNavbar.TempNavItem>
        )}
        {newDesign && (
          <StyledNavbar.NavItem to="new-design">
            <StyledNavbar.NewLabel>NEW</StyledNavbar.NewLabel>
            <PaintIcon width={22} height={22} /> {t('design')}
            <p style={{ fontSize: 7 }}>({t('internal')})</p>
          </StyledNavbar.NavItem>
        )}
      </StyledNavbar.Nav>
      <StyledNavbar.HelpButton onClick={modalToggle}>
        <QuestionIcon width={22} height={22} /> {t('need_help')}
      </StyledNavbar.HelpButton>
    </StyledNavbar.Root>
  );
};
