import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { ACTIVE_ACCOUNT_ID, ACTIVE_PROJECT_ID } from '../constants';
import { useAuth } from './auth';
import useUpdateLDContext from '../hooks/useUpdateLDContext';

interface IAccountContext {
  activeAccountId?: string;
  setActiveAccountId: (id: string, projectId?: string) => void;
  activeProjectId?: string;
  setActiveProjectId: (value: string | undefined) => void;
  resetActiveProject: () => void;
}

export const AccountContext = React.createContext<IAccountContext>({} as IAccountContext);

export const AccountProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { authenticated } = useAuth();
  const [activeAccountId, _setActiveAccountId] = useState<string | undefined>();
  const [activeProjectId, setActiveProjectId] = useState<string | undefined>();

  useUpdateLDContext({ activeAccountId, activeProjectId });

  useEffect(() => {
    if (!authenticated) {
      _setActiveAccountId(undefined);
      setActiveProjectId(undefined);
    }
  }, [authenticated]);

  useEffect(() => {
    activeAccountId &&
      localStorage.getItem(ACTIVE_ACCOUNT_ID) !== activeAccountId &&
      localStorage.setItem(ACTIVE_ACCOUNT_ID, activeAccountId);
  }, [activeAccountId]);

  useEffect(() => {
    activeProjectId &&
      localStorage.getItem(ACTIVE_PROJECT_ID) !== activeProjectId &&
      localStorage.setItem(ACTIVE_PROJECT_ID, activeProjectId);
  }, [activeProjectId]);

  const setActiveAccountId = useCallback((id: string, projectId?: string) => {
    _setActiveAccountId(id);
    projectId ? setActiveProjectId(projectId) : resetActiveProject();
  }, []);

  const resetActiveProject = () => {
    setActiveProjectId('');
    localStorage.removeItem(ACTIVE_PROJECT_ID);
  };

  const value = useMemo(
    () => ({
      activeAccountId,
      setActiveAccountId,
      activeProjectId,
      setActiveProjectId,
      resetActiveProject,
    }),
    [activeAccountId, activeProjectId],
  );

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

export function useAccount(): IAccountContext {
  return useContext(AccountContext);
}
