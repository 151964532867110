import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { Locale } from '../../../../locales/i18n.d';
import { DataTable } from '../../../../components/DataTable';
import { toLocalePrice } from '../../../../utils/currency';
import { IBillAgentExpenses } from '../../types';
import { getExpenseColor } from '../../../../utils/billing';
import { billingOrderedExpenses } from '../../../../constants';

interface Props {
  agentExpenses: IBillAgentExpenses[];
  currency: string;
}

export const DetailsTable: React.FC<Props> = React.memo(({ agentExpenses, currency }) => {
  const { t, i18n } = useTranslation('billing');
  const locale = i18n.language as Locale;

  const expenses = agentExpenses
    ?.map((item: IBillAgentExpenses, i) => ({
      ...item,
      id: i,
      item: item.name,
      price_per_unit: item.price.amount,
      amount: item.amount,
      total_price: item.consumed.amount,
      color: getExpenseColor(i, item.name),
    }))
    .sort(
      (a, b) => billingOrderedExpenses.indexOf(a.item) - billingOrderedExpenses.indexOf(b.item),
    );

  const columns: ColumnProps<IBillAgentExpenses>[] = [
    {
      title: '',
      dataIndex: 'color',
      width: 30,
      render: (color: string) => <span className="dot" style={{ background: color }} />,
    },
    {
      title: t('item'),
      dataIndex: 'item',
      width: 240,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('type'),
      dataIndex: 'type',
      width: 200,
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: t('price_per_unit'),
      dataIndex: 'price_per_unit',
      width: 160,
      render: (consumption: string) => `${currency} ` + toLocalePrice(consumption, locale),
      sorter: (a, b) => Number(a.price.amount) - Number(b.price.amount),
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      width: 120,
      sorter: (a, b) => Number(a.amount) - Number(b.amount),
    },
    {
      title: t('total_spend'),
      dataIndex: 'total_price',
      width: 160,
      render: (consumption: string) => `${currency} ` + toLocalePrice(consumption, locale),
      sorter: (a, b) => Number(a.consumed.amount) - Number(b.consumed.amount),
    },
  ];

  return <DataTable dataSource={expenses} columns={columns} pagination={false} />;
});
DetailsTable.displayName = 'DetailsTable';
