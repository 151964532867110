import { ReactElement } from 'react';

interface Props {
  title: string;
  Component: ReactElement;
}

export const TitleComponent: React.FC<Props> = ({ title, Component }) => {
  return (
    <>
      {title} ({Component})
    </>
  );
};
