import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'ramda';
import { ReactComponent as SignInIcon } from '../../../assets/icons/sign-in.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { ForgetPassWordLocaleDiv, ForgotPassword, StyledLoginForm } from '../Login.styles';
import { useAuth } from '../../../state/auth';
import { Alert } from '../../../components/Alert';
import { ErrorMessages, LoginFormErrors } from '../ErrorMessages';
import { SelectLocal } from './SelectLocal';
import { FloatField } from '../../../components/FloatField';
import { Paths } from '../../../types/paths';

export type LoginFormValues = {
  username: string;
  password: string;
};

export const LoginForm: React.FC = () => {
  const { t } = useTranslation('login');
  const [form] = Form.useForm();
  const auth = useAuth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<LoginFormErrors>({});
  const [authError, setAuthError] = useState<boolean>(false);
  const { forgotPassword } = useFlags();

  function validate(username: string, password: string) {
    const errors: LoginFormErrors = {};

    if (!username && !password) {
      errors.username = {
        title: t('enter_username_password'),
        body: t('username_to_login'),
      };
    } else if (!username && form.isFieldTouched('username')) {
      errors.username = {
        title: t('enter_username'),
        body: t('doesnt_work_without_username'),
      };
    }

    if (!password && username && form.isFieldTouched('password')) {
      errors.password = {
        title: t('enter_password'),
        body: t('password_to_login'),
      };
    }
    setErrors(errors);
    if (!isEmpty(errors)) return Promise.reject();
  }

  async function onSubmit(values: LoginFormValues) {
    setAuthError(false);
    // trigger fields touched to display custom validation error
    form.setFieldsValue({
      username: values.username?.trim() || '',
      password: values.password?.trim() || '',
    });
    try {
      await form.validateFields();
      const response = await auth.signIn(form.getFieldsValue(true), () => {
        navigate(Paths.index(), { replace: true });
      });
      if (response?.error && response.error.status === 401) setAuthError(true);
    } catch (e) {
      if (e.response && e.response.status >= 500) {
        navigate('*', { state: { emptyErrorMessage: true } });
      }
    }
  }

  return (
    <>
      <h2>{t('login_account')}</h2>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <StyledLoginForm>
          <div>
            <Form.Item noStyle shouldUpdate>
              {({ setFieldValue }) => {
                return (
                  <FloatField
                    name="username"
                    label={t('username')}
                    dependencies={['password']}
                    validateStatus={Boolean(errors.username) ? 'error' : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setAuthError(false);
                      setFieldValue('username', e.target.value);
                    }}
                    rules={[
                      ({ getFieldValue }) => ({
                        async validator(_, value) {
                          await validate(value, getFieldValue('password'));
                        },
                      }),
                    ]}
                  />
                );
              }}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldsValue, setFieldValue, isFieldTouched }) => {
                const { password } = getFieldsValue();
                return (
                  <FloatField
                    name="password"
                    label={t('label_password')}
                    type="password"
                    dependencies={['username']}
                    validateStatus={
                      (Boolean(errors.password) && isFieldTouched('password')) ||
                      (Boolean(errors.username) && !password)
                        ? 'error'
                        : ''
                    }
                    rules={[
                      ({ getFieldValue }) => ({
                        async validator(_, value) {
                          await validate(getFieldValue('username'), value);
                        },
                      }),
                    ]}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setAuthError(false);
                      setFieldValue('password', e.target.value);
                    }}
                    style={{ marginBottom: 0 }}
                  />
                );
              }}
            </Form.Item>
            <ForgetPassWordLocaleDiv>
              {forgotPassword && (
                <ForgotPassword to="recover-account">
                  <LockIcon width={12} height={12} /> {t('forgot_password')}
                </ForgotPassword>
              )}
              <SelectLocal />
            </ForgetPassWordLocaleDiv>
            {authError && (
              <Alert title={t('username_password_no_match')}>{t('password_error')}</Alert>
            )}
            <ErrorMessages errors={errors} />
          </div>
          <div>
            <Button block type="primary" htmlType="submit">
              {t('login')}
              <SignInIcon width={15} height={15} />
            </Button>
          </div>
        </StyledLoginForm>
      </Form>
    </>
  );
};
