import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content } from '../../../../components/Content';
import { StyledCard, CardInfo } from '../../AI.style';
import { Paths } from '../../../../types/paths';
import { ViewLink } from '../../../../components/ViewLink';

export const ConfigurationList: React.FC = () => {
  const { t } = useTranslation('ai');

  return (
    <Content imgBg={false}>
      <StyledCard title={t('fallback_behav')} bordered={false}>
        <CardInfo> {t('def_cust_exp')}</CardInfo>
        <ViewLink to={Paths.aiFallbackBehaviour()} />
      </StyledCard>
    </Content>
  );
};
