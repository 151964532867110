const account_step_1_fields = [
  'sales_confirmation_number',
  'name',
  'account_info.currency',
  'start_at',
  'end_at',
  'account_info.billing_time_unit',
  'account_info.billing_time_amount',
];
const account_step_2_fields = [
  'customer_info.business_name',
  'customer_info.company_number',
  'customer_info.vat_percent',
  'customer_info.email',
  'customer_info.contact_number',
  'customer_info.address',
  'customer_info.town',
  'customer_info.country',
  'customer_info.post_code',
];
const account_step_3_fields = [
  'vendor_info.business_name',
  'vendor_info.company_number',
  'vendor_info.vat_percent',
  'vendor_info.email',
  'vendor_info.contact_number',
  'vendor_info.address',
  'vendor_info.town',
  'vendor_info.country',
  'vendor_info.post_code',
];

export const accountStepsFields = [
  account_step_1_fields,
  account_step_2_fields,
  account_step_3_fields,
];
