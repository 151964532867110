import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { Content } from '../../../../components/Content';
import { DataTable } from '../../../../components/DataTable';
import { ActionButton } from '../../../../components/ActionButton';
import { useAccount } from '../../../../state/account';
import { ProjectUsers } from '../../../../services/project-users';
import { DeleteConfirmation } from '../../../../components/DeleteConfirmation';
import { useDelete } from '../../../../hooks/useDelete';
import { NoProject } from '../../../../components/NoProject';
import useStateLoading from '../../../../hooks/useStateLoading';
import { useAuth } from '../../../../state/auth';
import { Paths } from '../../../../types/paths';
import { getName } from '../../../../utils/user';

export interface IUser {
  id: number;
  external_id: string;
  external_data: {
    id: string;
    email: string;
    emailVerified: boolean;
    enabled: boolean;
    username: string;
    firstName: string;
    lastName: string;
    requiredActions: Array<any>;
    totp: boolean;
  } | null;
  username: string;
  created_at: string;
  updated_at: string;
}

export const UsersList: React.FC = () => {
  const { t } = useTranslation('project_settings');
  const navigate = useNavigate();
  const { activeAccountId, activeProjectId } = useAccount();
  const { isStateLoading, isProject } = useStateLoading();
  const { username } = useAuth();
  const { data, isLoading } = useQuery<IUser[], string>(
    [ProjectUsers.queryKey, activeAccountId, activeProjectId],
    ProjectUsers.get,
    { enabled: !!activeAccountId && !!activeProjectId },
  );
  const { mutate: deleteItem, isLoading: isDeleteLoading } = useDelete(
    [ProjectUsers.queryKey],
    ProjectUsers.deleteUser,
  );

  const handleDelete = React.useCallback(
    async id =>
      activeAccountId &&
      activeProjectId &&
      (await deleteItem({ id, activeAccountId, activeProjectId })),
    [activeAccountId, activeProjectId],
  );

  const columns: ColumnProps<IUser>[] = [
    {
      title: t('user'),
      dataIndex: 'external_data',
      render: value => getName(value),
      width: '40%',
      sorter: (a, b) => getName(a.external_data).localeCompare(getName(b.external_data)),
    },
    {
      title: t('username'),
      dataIndex: ['username'],
      render: username => username || '-',
      sorter: (a, b) => (a.username || '-').localeCompare(b.username || '-'),
    },
    {
      key: 'actions',
      width: 64,
      render: record => (
        <DeleteConfirmation
          id={record.id}
          onActionDelete={handleDelete}
          disabled={record.username === username}
        />
      ),
      className: 'actions',
    },
  ];

  const ButtonInvite = <ActionButton type="invite" onClick={() => navigate(Paths.usersInvite())} />;

  if (!isProject && !isStateLoading) return <NoProject />;
  return (
    <Content imgBg={false} Actions={[ButtonInvite]}>
      <DataTable
        dataSource={data}
        columns={columns}
        loading={isLoading || isDeleteLoading}
        isClickable={false}
      />
    </Content>
  );
};
