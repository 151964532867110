import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Form, InputNumber, Select, Input } from 'antd';
import { formItemClass } from '../../../../theme';
import { Space } from '../../../../components/Space';
import { CurrencySelector } from '../../../../components/CurrencySelector';
import { getCurrencySymbol } from '../../../../utils/currency';
import { CurrencySymbol } from '../../../../constants';

const { Option } = Select;

export const AIAutomationBilling: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const form = Form.useFormInstance();
  const currency = Form.useWatch(['billing_attributes', 'currency'], form);
  const contractType = Form.useWatch(['billing_attributes', 'contractType'], form);
  const [symbol, setSymbol] = useState<CurrencySymbol>();

  useEffect(() => {
    setSymbol(getCurrencySymbol(currency));
  }, [currency]);

  const interactionsPriceField = (
    <Form.Item
      name={['billing_attributes', 'interactionsPrice']}
      label={t('interactions_price')}
      className={formItemClass.base}
      rules={[{ required: true }]}
    >
      <InputNumber prefix={symbol} min={0} placeholder="###" />
    </Form.Item>
  );

  return (
    <div style={style}>
      <Divider style={{ margin: '0 0 8px' }} />
      <Space>
        <Form.Item
          name={['billing_attributes', 'currency']}
          label={t('currency')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <CurrencySelector placeholder={t('select_currency')} isSymbol={true} />
        </Form.Item>
        <Form.Item
          name={['billing_attributes', 'platformFee']}
          label={t('platform_fee')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <InputNumber prefix={symbol} min={0} placeholder="###" />
        </Form.Item>
      </Space>
      <Divider />
      <Form.Item
        name={['billing_attributes', 'contractType']}
        label={t('contract_type')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select placeholder={t('contract_type')}>
          <Option value="annual">{t('annual')}</Option>
          <Option value="pay_as_you_go">{t('pay_as_you_go')}</Option>
        </Select>
      </Form.Item>
      {contractType === 'annual' ? (
        <div>
          <Space>
            <Form.Item
              name={['billing_attributes', 'committedVolume']}
              label={t('committed_volume')}
              className={formItemClass.base}
              rules={[{ required: true }]}
            >
              <Input placeholder={t('committed_volume')} />
            </Form.Item>
            <Form.Item
              name={['billing_attributes', 'termMonths']}
              label={t('term_months')}
              className={formItemClass.base}
              rules={[{ required: true }]}
            >
              <Select placeholder={t('term_months')}>
                <Option value="12">12 {t('months')}</Option>
                <Option value="24">24 {t('months')}</Option>
                <Option value="36">36 {t('months')}</Option>
              </Select>
            </Form.Item>
          </Space>
          <Space>
            {interactionsPriceField}
            <Form.Item
              name={['billing_attributes', 'overageInteractionsPrice']}
              label={t('overage_interactions_price')}
              className={formItemClass.base}
              rules={[{ required: true }]}
            >
              <InputNumber prefix={symbol} min={0} placeholder={t('overage_interactions_price')} />
            </Form.Item>
          </Space>
        </div>
      ) : (
        interactionsPriceField
      )}
      <Divider />
      <Space>
        <Form.Item
          name={['billing_attributes', 'externalAnalyticsApiPrice']}
          label={t('analytics_api_price')}
          className={formItemClass.base}
        >
          <InputNumber prefix={symbol} min={0} placeholder="###" />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.project_languages_attributes !== currentValues.project_languages_attributes
          }
        >
          {({ getFieldValue }) => (
            <Form.Item
              name={['billing_attributes', 'multilangualInteractionsPrice']}
              label={t('multilingual_interactions_price')}
              className={formItemClass.base}
            >
              <InputNumber
                prefix={symbol}
                min={0}
                placeholder={
                  getFieldValue('project_languages_attributes')?.length === 0 ? '-' : '###'
                }
                disabled={getFieldValue('project_languages_attributes')?.length === 0}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={['billing_attributes', 'supportServicePrice']}
          label={t('support_service_price')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <InputNumber prefix={symbol} min={0} placeholder="###" />
        </Form.Item>
        <Form.Item
          name={['billing_attributes', 'optimisationPrice']}
          label={t('optimisation_price')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <InputNumber prefix={symbol} min={0} placeholder="###" />
        </Form.Item>
      </Space>
    </div>
  );
};
