import React, { useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { ActionButton } from '../ActionButton';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as BanIcon } from '../../assets/icons/ban.svg';
import { formItemClass } from '../../theme';

export interface IDisableConfirmation {
  onDisable?: () => void;
  title: string;
  description: string;
  buttonText?: string;
  confirmationText?: string;
}

export const DisableConfirmation: React.FC<IDisableConfirmation> = ({
  onDisable,
  title,
  description,
  buttonText,
  confirmationText,
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSubmit = () => form.submit();
  const handleOk = async () => onDisable?.();
  const handleCancel = () => {
    setVisible(false);
    confirmationText && form.resetFields();
  };
  const showModal = () => setVisible(true);

  const confirmationInput = () => (
    <>
      {confirmationText && (
        <Form
          form={form}
          layout="vertical"
          initialValues={{ confirmation: '' }}
          onFinish={handleOk}
          requiredMark={false}
          validateTrigger="onBlur"
        >
          <Form.Item
            name="confirmation"
            label={t('type_disable_to_confirm', { text: `“${confirmationText}”` })}
            className={formItemClass.base}
            rules={[
              () => ({
                validator: async (_, value) => {
                  if (value !== confirmationText)
                    throw new Error(t('type_disable_to_confirm', { text: confirmationText }));
                },
              }),
            ]}
          >
            <Input placeholder={t('type_disable_to_confirm', { text: `“${confirmationText}”` })} />
          </Form.Item>
        </Form>
      )}
    </>
  );

  return (
    <>
      <ActionButton type="disable" text={buttonText} onClick={showModal} />
      <Modal
        width={408}
        title={
          <>
            <BanIcon width={16} height={16} /> {title}
          </>
        }
        centered
        open={visible}
        className="modal-danger"
        footer={[
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />,
          <ActionButton
            type="disable"
            key="disable"
            onClick={confirmationText ? handleSubmit : handleOk}
          />,
        ]}
        onCancel={handleCancel}
      >
        <p>
          <Trans>{description}</Trans>
        </p>
        {confirmationText && confirmationInput()}
      </Modal>
    </>
  );
};
