import { route } from '../utils/routes';

export const Paths = {
  index: route('/'),
  aiIntegration: route('/integration'),
  accounts: route('/account-settings'),
  accountCreate: route('/account-settings/create'),
  accountEdit: route<'id'>('/account-settings/:id'),
  analytics: route('/analytics'),
  analyticDashboards: route('/analytics/dashboards'),
  analyticDashboardView: route<'id'>('/analytics/dashboards/:id'),
  analyticLooks: route('/analytics/looks'),
  analyticLookView: route<'id'>('/analytics/looks/:id'),
  analyticExplore: route('/analytics/explore'),
  analyticExploreView: route<'id'>('/analytics/explore/:id'),
  analyticFAReports: route('/analytics/fAReports'),
  analyticFAReportView: route<'id'>('/analytics/fAReports/:id'),
  billing: route('/billing'),
  billingSummary: route('/billing/summary'),
  billingDetails: route('/billing/details'),
  designV1: route('/design'),
  design: route('/new-design'),
  designEdit: route<'id'>('/new-design/:id'),
  oldKnowledge: route('/knowledge'),
  knowledge: route('/new-knowledge'),
  knowledgePresentations: route('/new-knowledge/presentations'),
  knowledgePresentationsCreate: route('/new-knowledge/presentations/create'),
  knowledgePresentationsEdit: route<'id'>('/new-knowledge/presentations/:id'),
  knowledgeParameters: route('/new-knowledge/parameters'),
  knowledgeParametersCreate: route('/new-knowledge/parameters/create'),
  knowledgeParametersEdit: route<'id'>('/new-knowledge/parameters/:id'),
  knowledgeIntents: route('/new-knowledge/intents'),
  knowledgeTranslations: route('/new-knowledge/translations'),
  knowledgeTranslationsEdit: route<'id'>('/new-knowledge/translations/:id'),
  knowledgeWorkflow: route('/new-knowledge/workflow'),
  login: route('/login'),
  loginRecover: route('/login/recover-account'),
  loginRecoverExpired: route('/login/recover-account-expired'),
  loginRecoverSuccess: route('/login/recover-account-success'),
  loginPasswordReset: route('/login/password-reset/*'),
  loginPasswordResetSuccess: route('/login/password-reset-success'),
  loginInviteExpired: route('/login/invite-link-expired'),
  loginCreatePassword: route('/login/create-password'),
  loginCreatePasswordComplete: route('/login/create-password-complete'),
  projects: route('/projects'),
  projectCreate: route('/projects/create'),
  projectEdit: route<'id'>('/projects/:id'),
  users: route('/users'),
  usersInvite: route('/users/invite'),
  userEdit: route<'id'>('/users/:id'),
  userSettings: route('/user-settings'),
  termsConditions: route('/terms-conditions'),
  fairUsePolicy: route('/fair-use-policy'),
  securityPolicy: route('/security-policy'),
  health: route('/health'),
  wellbeing: route('wellbeing'),
  pulse: route('/pulse'),
  pulseInsights: route('/pulse/insights'),
  pulseSchedules: route('/pulse/schedules'),
  pulseScheduleCreate: route('/pulse/schedules/create'),
  pulseScheduleEdit: route('/pulse/schedules/:id'),
  ai: route('/ai'),
  aiConfiguration: route('/ai/configuration'),
  aiFallbackBehaviour: route('/ai/configuration/fallback-behaviour'),
  aiFallbackBehaviourCreate: route('/ai/configuration/fallback-behaviour/create'),
  aiFallbackBehaviourEdit: route<'id'>('/ai/configuration/fallback-behaviour/:id'),
};
