import { useTranslation } from 'react-i18next';
import { DataTable } from '../../../../components/DataTable';
import { ColumnProps } from 'antd/es/table';
import { IExpensesByAgentGroup } from '../../types';
import { toLocalePrice } from '../../../../utils/currency';

export interface Props {
  currency: string;
  locale: string;
  expenses: IExpensesByAgentGroup[];
}

export const PriceTable: React.FC<Props> = ({ currency, locale, expenses }) => {
  const { t } = useTranslation('billing');
  const columns: ColumnProps<IExpensesByAgentGroup>[] = [
    {
      title: '',
      dataIndex: 'color',
      width: 30,
      render: (color: string) => <span className="dot" style={{ background: color }} />,
    },
    {
      title: t('name'),
      dataIndex: 'type',
    },
    {
      title: t('spend'),
      dataIndex: 'consumption',
      width: 130,
      render: (consumption: string) => (
        <b style={{ marginLeft: -12 }}>{`${currency} ` + toLocalePrice(consumption, locale)}</b>
      ),
    },
  ];

  return (
    <DataTable
      dataSource={expenses}
      columns={columns}
      size="small"
      pagination={false}
      style={{ marginTop: 40 }}
    />
  );
};
