import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ReportsTable } from '../../../TableComponent/table';
import { ReportsService } from '../../../../../services/reports';
import { Reports } from '../../types';
import { useDelete } from '../../../../../hooks/useDelete';
import { Content } from '../../../../../components/Content';
import { UppercaseLinkBold } from '../../../Analyics.styles';
import { useAccount } from '../../../../../state/account';
import { useIntegrations } from '../../../../../state/integrations';
import { Paths } from '../../../../../types/paths';
import { AiIntegrationParams } from '../../../../../services/api';

export const DashboardsList: React.FC = () => {
  const isMounted = useRef(false);
  const { t } = useTranslation('reports');
  const navigate = useNavigate();
  const { activeProjectId } = useAccount();
  const { aiIntegration } = useIntegrations();
  const [isFetching, setIsfething] = useState<boolean>(false);
  const { isLoading, data, refetch } = useQuery<Reports[]>(
    [ReportsService.queryKeyDashboards, activeProjectId, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      ReportsService.getDashboards({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );
  const { mutate: deleteItem, isLoading: isDeleteLoading } = useDelete(
    [ReportsService.queryKeyDashboards],
    ReportsService.deleteDashboard,
  );
  const handleDelete = React.useCallback(
    async id => aiIntegration && (await deleteItem({ id, aiIntegration })),
    [],
  );

  useEffect(() => {
    if (isMounted.current) {
      navigate(Paths.analytics());
    } else {
      isMounted.current = true;
    }
    async function fetchDashboards() {
      setIsfething(true);
      await refetch();
      setIsfething(false);
    }
    aiIntegration?.url && fetchDashboards();
  }, [activeProjectId, aiIntegration?.url]);

  return (
    <>
      <Content imgBg={false} header={false}>
        <UppercaseLinkBold to=""> {t('dashboards')}</UppercaseLinkBold>
        <ReportsTable
          loading={isLoading || isDeleteLoading || isFetching}
          reports={data || []}
          handleRemove={handleDelete}
          type="dashboards"
        />
      </Content>
    </>
  );
};
