import { Button, Form, Input } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../theme';
import { Space } from '../../../components/Space';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock-alt.svg';
import { FixedBottomBar } from '../../../components/FixedBottomBar';
import { FormActions } from '../../../components/FormActions';
import { ActionButton } from '../../../components/ActionButton';
import { useAuth } from '../../../state/auth';
import { useGetItem } from '../../../hooks/useGetItem';
import { UserService } from '../../../services/user';
import { IUser } from '../../Projects/Users/UsersList';

interface IUserValues {
  first_name: string;
  last_name: string;
  email: string;
}

export const UserSettingsForm: React.FC = () => {
  const { t } = useTranslation('user_settings');
  const { changePassword } = useFlags();
  const navigate = useNavigate();
  const { id } = useAuth();
  const [form] = Form.useForm();
  const { data } = useGetItem<IUser>([UserService.queryKey, id], UserService.find, {
    enabled: !!id,
  });

  if (!data?.external_data) return null;

  const initialValues: IUserValues = {
    first_name: data.external_data.firstName,
    last_name: data.external_data.lastName,
    email: data.external_data.email,
  };

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Space>
        <Form.Item name="first_name" label={t('first_name')} className={formItemClass.base}>
          <Input placeholder={t('first_name')} disabled />
        </Form.Item>
        <Form.Item name="last_name" label={t('last_name')} className={formItemClass.base}>
          <Input placeholder={t('last_name')} disabled />
        </Form.Item>
      </Space>
      <Form.Item name="email" label={t('email')} className={formItemClass.base}>
        <Input placeholder={t('email')} disabled />
      </Form.Item>
      {changePassword && (
        <Space size={4}>
          <Form.Item
            name="password"
            label={t('password')}
            style={{ width: 245 }}
            extra={
              <>
                {t('last_changed')} <b>DD/MM/YY</b>
              </>
            }
          >
            <Input type="password" placeholder={t('password')} disabled />
          </Form.Item>
          <Button
            type="default"
            style={{ width: 111, marginTop: 24 }}
            icon={<LockIcon width={13} height={14} />}
          >
            {t('change')}
          </Button>
        </Space>
      )}

      <FixedBottomBar>
        <FormActions>
          <ActionButton type="back" onClick={() => navigate(-1)} />
        </FormActions>
      </FixedBottomBar>
    </Form>
  );
};
