import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useAuth } from '../state/auth';
import { useRouteConfig } from './routeConfig';

export const Router = (): React.ReactElement | null => {
  const { authenticated } = useAuth();
  const routes = useRouteConfig(authenticated);

  return <RouterProvider router={createBrowserRouter(routes)} />;
};
