import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { WellbeingNav } from './WellbeingNav';
import { Reports } from '../Analytics/Reports/types';
import { ReportsService } from '../../services/reports';
import { useIntegrations } from '../../state/integrations';
import { OverlaySpinner } from '../../components/Spinner';
import { useAccount } from '../../state/account';
import { Paths } from '../../types/paths';
import { AiIntegrationParams } from '../../services/api';

export const Wellbeing: React.FC = () => {
  const { aiIntegration } = useIntegrations();
  const { activeAccountId, activeProjectId } = useAccount();
  const navigate = useNavigate();
  const { isFetching, data } = useQuery<Reports[]>(
    [ReportsService.queryKeyDashboards, activeProjectId],
    ({ signal }) =>
      ReportsService.getDashboards({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );
  const [isIframeLoading, setIsIframeLoading] = React.useState<boolean>(true);
  const subNavHeight = '35px';
  const isIlyaTestProject = activeAccountId === '41' && activeProjectId === '147';

  const wellbeingURL = useMemo(
    () => data?.find(item => item.name.toLowerCase() === 'wellbeing team manager dashboard')?.url,
    [data],
  );

  useEffect(() => {
    !isIlyaTestProject && navigate(Paths.analytics(), { replace: true });
  }, [isIlyaTestProject]);

  return (
    <>
      <WellbeingNav />
      {wellbeingURL && (
        <iframe
          width="100%"
          style={{ paddingTop: subNavHeight }}
          height="100%"
          src={wellbeingURL}
          onLoad={() => setIsIframeLoading(false)}
        />
      )}
      <OverlaySpinner loading={isFetching || isIframeLoading} />
    </>
  );
};
