import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from './api';

export type RoutingMethod = 'task_router' | 'direct_dial' | 'genesys';

export type RouterProtocol = 'pstn' | 'sip';

interface IRouter {
  config: string;
  id: number;
  is_default: boolean;
  name: string;
  outbound_contact_uri: string;
  protocol: RouterProtocol;
  routing_method: RoutingMethod;
  sip_refer_enabled: boolean;
  team_id?: number;
}

const queryKey = 'routers';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IRouter[]> => {
  const response = await integrationQuery(`/api/routers`, {
    apiData,
    signal,
  });
  return response?.routers;
};

export const RouterService = { queryKey, get };
