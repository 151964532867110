import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { IKnowledgeUrl } from '../../state/integrations';
import { IIntents, IBatchSyncResponse, IBatches } from '../../pages/Knowledge/types';
import { knowledgeQuery } from '../api';

const queryKey = 'knowledge-intents';
const importQueryKey = 'knowledge-import-intents';
const publishQueryKey = 'knowledge-publish-intents';

const get = async (
  data: IKnowledgeUrl,
  queryString: string,
  signal: QueryFunctionContext['signal'],
): Promise<IIntents> => {
  return await knowledgeQuery(`/destination_entities${queryString}`, { data, signal });
};

const getBatchResponse = async (
  data: IKnowledgeUrl,
  signal: QueryFunctionContext['signal'],
): Promise<IBatches> => {
  return await knowledgeQuery(`/synchronisation_batches?page=1&pageSize=10&q[s]=updated_at desc`, {
    data,
    signal,
  });
};

const publish = ({
  data,
  values,
}: {
  data: IKnowledgeUrl;
  values: string[];
}): Promise<IBatchSyncResponse> => {
  return knowledgeQuery('/synchronisation_batches', {
    method: 'POST',
    data: {
      ...data,
      payload: {
        data: {
          attributes: {
            synchronisation_ids: values,
          },
          type: 'synchronisation_batches',
        },
      },
    },
  });
};
const sync = async (
  knowledgeURL: IKnowledgeUrl,
  queryString: string,
  signal: QueryFunctionContext['signal'],
): Promise<string> => {
  return await knowledgeQuery(
    `/remote_destinations/streams/imports
`,
    { data: knowledgeURL, signal },
  );
};

export const KnowledgeIntentService = {
  queryKey,
  importQueryKey,
  publishQueryKey,
  get,
  publish,
  sync,
  getBatchResponse,
};
