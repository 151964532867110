import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { IKnowledgeUrl } from '../../state/integrations';
import {
  IPresentation,
  IPresentationValues,
  IPresentations,
  IPresentationsDeleteValues,
} from '../../pages/Knowledge/types';
import { ApiResponse } from '../types';
import { knowledgeQuery } from '../api';

type DeleteProps = {
  id: string;
  data: IKnowledgeUrl;
};

const queryKey = 'knowledge-presentations';

const get = async (
  knowledgeURL: IKnowledgeUrl,
  queryString: string,
  signal: QueryFunctionContext['signal'],
): Promise<IPresentations> => {
  return await knowledgeQuery(`/presentations${queryString}`, { data: knowledgeURL, signal });
};

const create = ({
  data,
  values,
}: {
  data: IKnowledgeUrl;
  values: IPresentationValues;
}): Promise<IPresentation> => {
  return knowledgeQuery('/presentations', {
    method: 'POST',
    data: {
      ...data,
      payload: {
        data: {
          attributes: values,
          type: 'presentation',
        },
      },
    },
  });
};

const deletePresentation = async ({ id, data }: DeleteProps): Promise<ApiResponse> =>
  await knowledgeQuery(`/presentations/${id}`, {
    method: 'DELETE',
    data,
  });

const deletePresentations = ({
  data,
  values,
}: {
  data: IKnowledgeUrl;
  values: IPresentationsDeleteValues;
}): Promise<any> => {
  return knowledgeQuery('', {
    method: 'PUT',
    data: {
      ...data,
      payload: {
        data: {
          attributes: {
            presentations_attributes: values,
          },
          type: 'projects',
        },
      },
    },
  });
};

const find = async (
  data: IKnowledgeUrl,
  id: string,
  signal: QueryFunctionContext['signal'],
): Promise<IPresentation> => {
  return await knowledgeQuery(`/presentations/${id}`, { data, signal });
};

const update = ({
  id,
  data,
  values,
}: {
  id: string;
  data: IKnowledgeUrl;
  values: IPresentationValues;
}): Promise<IPresentation> => {
  return knowledgeQuery(`/presentations/${id}`, {
    method: 'PUT',
    data: {
      ...data,
      payload: {
        data: {
          attributes: values,
          type: 'presentations',
        },
      },
    },
  });
};
export const KnowledgePresentationService = {
  queryKey,
  get,
  create,
  find,
  update,
  deletePresentation,
  deletePresentations,
};
