import { ReactElement } from 'react';
import { Translation } from 'react-i18next';

export const AUTH_KEY = 'CONSOLE_AUTH';

export const ACTIVE_ACCOUNT_ID = 'Console-account-id';
export const ACTIVE_PROJECT_ID = 'Console-project-id';
export const ACTIVE_USER = 'Console-user';
export const ACTIVE_PROJECT_NAME = 'Console-project-name';
export const ACTIVE_ACCOUNT_NAME = 'Console-account-name';

export const KNOWLEDGE_INTRO = 'Console-knowledge-intro';

export const LAST_TIMESTAMP = 'Console-lastTimeStamp';

export const CONSOLE_CHAT_ID = 'console-chat';

export const initialLDContext = {
  kind: 'multi',
  user: {
    anonymous: true,
  },
  account: {
    anonymous: true,
  },
  project: {
    anonymous: true,
  },
};

export const currencies = [
  {
    code: 'usd',
    symbol: '$',
  },
  {
    code: 'eur',
    symbol: '€',
  },
  {
    code: 'gbp',
    symbol: '£',
  },
  {
    code: 'sgd',
    symbol: '$',
  },
] as const;
export type CurrencyCode = typeof currencies[number]['code'];
export type CurrencySymbol = typeof currencies[number]['symbol'];
export const initialCurrency = '$';

export type Weekday =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';
export const days: Record<Weekday, ReactElement> = {
  monday: <Translation ns={'account_settings'}>{t => t('monday')}</Translation>,
  tuesday: <Translation ns={'account_settings'}>{t => t('tuesday')}</Translation>,
  wednesday: <Translation ns={'account_settings'}>{t => t('wednesday')}</Translation>,
  thursday: <Translation ns={'account_settings'}>{t => t('thursday')}</Translation>,
  friday: <Translation ns={'account_settings'}>{t => t('friday')}</Translation>,
  saturday: <Translation ns={'account_settings'}>{t => t('saturday')}</Translation>,
  sunday: <Translation ns={'account_settings'}>{t => t('sunday')}</Translation>,
};

export type Period = 'week' | 'month';
export const billingPeriod: Record<Period, ReactElement> = {
  week: <Translation ns={'account_settings'}>{t => t('weekly')}</Translation>,
  month: <Translation ns={'account_settings'}>{t => t('monthly')}</Translation>,
};

export const billingOrderedExpenses = [
  'Console Platform',
  'Console Interactions',
  'Console Multi-Lingual Interaction',
  'Console Analytics API',
  'Support Services',
  'Optimisation Services',
];

export const CHANNELS = [
  {
    text: 'Web',
    value: 'web',
  },
  {
    text: 'FAQ',
    value: 'faq',
  },
  {
    text: 'Messenger',
    value: 'fb_messenger',
  },
  {
    text: 'WhatsApp',
    value: 'whatsapp',
  },
  {
    text: 'SMS',
    value: 'sms',
  },
  {
    text: 'Teams',
    value: 'ms_teams',
  },
  {
    text: 'Voice',
    value: 'voice',
  },
];

export const STATUSES = [
  {
    text: <Translation ns={'knowledge'}>{t => t('published')}</Translation>,
    value: 'success',
  },
  {
    text: <Translation ns={'knowledge'}>{t => t('publishing')}</Translation>,
    value: 'in_progress',
  },
  {
    text: <Translation ns={'knowledge'}>{t => t('missing')}</Translation>,
    value: 'missing',
  },
  {
    text: <Translation ns={'knowledge'}>{t => t('draft')}</Translation>,
    value: 'draft',
  },
  {
    text: <Translation ns={'knowledge'}>{t => t('failed')}</Translation>,
    value: 'failed',
  },
];

export const PROVIDERS = [
  {
    text: 'Dialogflow CX',
    value: 'dialogflow_cx',
  },
  {
    text: 'Dialogflow ES',
    value: 'dialogflow_es',
  },
];

export const VAFonts = [
  {
    option: 'Lato',
    value: "'Lato', sans-serif",
  },
  {
    option: 'Open Sans',
    value: "'Open Sans', sans-serif",
  },
  {
    option: 'Roboto',
    value: "'Roboto', sans-serif",
  },
  {
    option: 'Montserrat',
    value: "'Montserrat', sans-serif",
  },
  {
    option: 'PT Sans',
    value: "'PT Sans', sans-serif",
  },
];

export const VAButtonType = [
  { option: <Translation ns={'design'}>{t => t('outline')}</Translation>, value: 'outline' },
  { option: <Translation ns={'design'}>{t => t('filled')}</Translation>, value: 'filled' },
];

export const TRANSLATION_STATUS_LIST = ['missing', 'verified', 'unverified'];
