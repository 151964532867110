import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import { ColumnType, ReportsTableType } from '../Reports/types';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';

export const ReportsTable: React.FC<ReportsTableType> = React.memo(props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { reports, loading, handleRemove, type } = props;
  const { pathname } = location;

  const onCellHandler = (record: any) => {
    const { name, id } = record;
    return {
      onClick: () => navigate(`${pathname}/${id}`, { state: { name, type } }),
    };
  };

  const columns: ColumnType[] = [
    {
      title: t('name'),
      dataIndex: 'name',
      onCell: onCellHandler,
    },
  ];
  if (handleRemove) {
    columns.push({
      key: 'actions',
      width: 64,
      render: record => <DeleteConfirmation id={record.id} onActionDelete={handleRemove} />,
      className: 'actions',
    });
  }

  return <DataTable loading={loading} dataSource={reports} columns={columns} />;
});

ReportsTable.displayName = 'ReportsTable';
