import React from 'react';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as Ban } from '../../assets/icons/ban.svg';
import { theme } from '../../theme';

interface Props {
  enabled: boolean;
}

export const IconIndicator: React.FC<Props> = ({ enabled }) => {
  return enabled ? (
    <Check width={16} height={16} style={{ color: theme.successColor }} />
  ) : (
    <Ban width={16} height={16} style={{ color: theme.errorColor }} />
  );
};
