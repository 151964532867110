import { authMiddlewareQuery } from './api';
import { LoginFormValues } from '../pages/Login/LoginForm';
import { ForgotPasswordValues } from '../pages/Login/ForgotPassword';
import { PasswordResetValues } from '../pages/Login/PasswordReset';
import { IUsersValues } from '../pages/Projects/Users/UsersForm';
import { Locale } from '../locales/i18n.d';

const login = async (data: LoginFormValues): Promise<any> => {
  return authMiddlewareQuery(`/users/sessions`, {
    method: 'POST',
    data: data,
  });
};

const logout = async (data: { refresh_token: string }): Promise<any> => {
  return authMiddlewareQuery(`/users/sessions`, {
    method: 'DELETE',
    data: data,
  });
};

const refreshToken = async (data: { refresh_token: string }): Promise<any> => {
  return authMiddlewareQuery(`/users/sessions/tokens`, {
    method: 'POST',
    data: data,
  });
};

type ForgotPasswordPayload = ForgotPasswordValues & {
  locale: Locale;
};
const forgotPassword = async (data: ForgotPasswordPayload): Promise<any> => {
  return authMiddlewareQuery(`/users/forgot_password`, {
    method: 'POST',
    data: data,
  });
};

const resetPassword = async (data: PasswordResetValues): Promise<any> => {
  return authMiddlewareQuery(`/users/reset_password`, {
    method: 'POST',
    data: data,
  });
};

const queryKey = 'user_associateproject';

const addUsers = async (data: IUsersValues): Promise<any> => {
  return authMiddlewareQuery(`/users/user_associateproject`, {
    method: 'POST',
    data: data,
  });
};

export const AuthService = {
  queryKey,
  login,
  logout,
  refreshToken,
  forgotPassword,
  resetPassword,
  addUsers,
};
