import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { App } from 'antd';
import { Content } from '../../../../components/Content';
import { IUsersPayload, UsersForm } from '../UsersForm';
import { AuthService } from '../../../../services/auth';
import { useCreate } from '../../../../hooks/useCreate';
import { unexpectedError } from '../../../../utils/notification';
import { Paths } from '../../../../types/paths';

export const UsersNew: React.FC = () => {
  const { t } = useTranslation('project_settings');
  const [sendAnotherBtn, setSendAnotherBtn] = useState<boolean>(false);
  const [inviteBtn, setInviteBtn] = useState<boolean>(false);
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const { mutate: createItem } = useCreate<IUsersPayload>(
    [AuthService.queryKey],
    AuthService.addUsers,
  );

  const onSubmit = async (values: IUsersPayload) => {
    await createItem(values, {
      onSuccess: data => {
        if (!data.error) {
          if (sendAnotherBtn) {
            setSendAnotherBtn(false);
            notification['success']({
              message: t('users_added_successfully'),
            });
          }

          if (inviteBtn) {
            navigate(Paths.users());
            setInviteBtn(false);
            notification['success']({
              message: t('users_added_successfully'),
            });
          }
        } else {
          if (data.error.status === 400) {
            notification['error']({ message: data.error.data.error });
          } else if (data.error.status === 500) notification['error'](unexpectedError);
        }
      },
    });
  };

  return (
    <Content>
      <UsersForm
        initialValues={{ emails: [], email: '' }}
        onSubmit={onSubmit}
        sendAnotherBtn={sendAnotherBtn}
        setInviteBtn={setInviteBtn}
        setSendAnotherBtn={setSendAnotherBtn}
      />
    </Content>
  );
};
