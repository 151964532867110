import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/plugin/utc';
import { Key } from 'react';

export const formatDate = (date: string): string => {
  return dayjs(date).format('DD/MM/YYYY');
};

export const formatChinaDateFormat = (date: Dayjs): string => {
  return dayjs(date).format('YYYY-MM-DD');
};
export const getDateFormat = (date: string): Date => {
  const parts = date.split('/');
  return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
};

export const addFutureDate = (date: string, futureDateIndex: number, timeUnit: string): string => {
  const dateCopy = new Date(getDateFormat(date));
  if (timeUnit === 'week') {
    dateCopy.setDate(dateCopy.getDate() + 7 * futureDateIndex);
  } else {
    dateCopy.setMonth(dateCopy.getMonth() + futureDateIndex);
  }
  return dateCopy.toLocaleDateString();
};

export const getDatesInRange = (startDate: Date, endDate: Date): string[] => {
  const date = new Date(startDate.getTime());
  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates.map(item => item.toLocaleDateString('en-GB'));
};

export const getFormattedDate = (date: Key | boolean, isStart: boolean): string => {
  if (typeof date === 'boolean') return '-';
  const time = isStart ? '00:00:00.00' : '24:00:00.00';
  const dateObj = dayjs(`${date} ${time}`, 'DD/MM/YYYY HH:mm:ss.SS');
  return dateObj.format('YYYY-MM-DD HH:mm:ss.SS');
};

export const formatTime = (date: Dayjs): string => {
  return dayjs(date).format('HH:mm');
};
