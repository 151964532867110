import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import useSelectedMenuItem from '../../../hooks/useSelectedMenuItem';
import { ReactComponent as ConfigIcon } from '../../../assets/icons/config.svg';
import { Paths } from '../../../types/paths';
import { FixedNav } from '../../../components/FixedNav';

export const AINav = (): JSX.Element => {
  const { t } = useTranslation('ai');

  const menuItems = [
    {
      key: '/ai/configuration',
      icon: <ConfigIcon width={15} height={15} />,
      label: <Link to={Paths.aiConfiguration()}>{t('configuration')}</Link>,
    },
  ];

  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
