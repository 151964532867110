import React from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'rc-field-form/es/interface';
import { AccountInfo } from './AccountInfo';
import { CustomerInfo } from './CustomerInfo';
import { VendorInfo } from './VendorInfo';
import { WizardForm } from '../../../components/WizardForm';
import { IAccountsValues } from '../types';

interface IAccounts {
  initialValues?: Partial<IAccountsValues>;
  onSubmit: (values: IAccountsValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isEdit?: boolean;
  page?: number;
}

export const AccountsForm: React.FC<IAccounts> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEdit,
  page,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('account_settings');

  const onFinish = () => {
    const formData = form.getFieldsValue(true);
    onSubmit(formData, form.setFields);
  };

  const steps = [
    {
      title: t('account_info'),
      content: <AccountInfo />,
    },
    {
      title: t('customer_info'),
      content: <CustomerInfo />,
    },
    {
      title: t('vendor_info'),
      content: <VendorInfo />,
    },
  ];

  return (
    <>
      <WizardForm<Partial<IAccountsValues>>
        form={form}
        steps={steps}
        initialValues={initialValues}
        isEdit={isEdit}
        onFinish={onFinish}
        submitButton={submitButton}
        page={page}
      />
    </>
  );
};
