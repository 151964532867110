import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../state/auth';
import { useRouteConfig } from '../../config/routeConfig';
import { StyledBreadcrumbs } from './Breadcrumbs.styles';

export const Breadcrumbs: React.FC = () => {
  const { authenticated } = useAuth();
  const routes = useRouteConfig(authenticated);
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <StyledBreadcrumbs.Root>
      {breadcrumbs.map(({ match, breadcrumb }, index, array) => {
        return array.length - 1 === index ? (
          <StyledBreadcrumbs.Item key={match.pathname}>{breadcrumb}</StyledBreadcrumbs.Item>
        ) : (
          <StyledBreadcrumbs.Item key={match.pathname}>
            <NavLink to={match.pathname}>{breadcrumb}</NavLink>
          </StyledBreadcrumbs.Item>
        );
      })}
    </StyledBreadcrumbs.Root>
  );
};
