import React from 'react';
import { Column } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { chartGraph } from '../../../../utils/chartGraph';
import { getChartMeta } from '../../../../utils/billing';
import { CurrencySymbol } from '../../../../constants';

type Props = {
  currency: CurrencySymbol;
  lastBill: number;
  current: number;
};

export const ExpensesSummary: React.FC<Props> = React.memo(({ currency, lastBill, current }) => {
  const { t, i18n } = useTranslation('billing');
  const locale = i18n.language;
  const meta = getChartMeta(currency, locale);
  const checkForLastBill =
    lastBill === 0
      ? { expenses: t('current'), consumption: current }
      : { expenses: t('last_bill'), consumption: lastBill };

  const summaryConfig = {
    data: [checkForLastBill],
    meta,
    ...chartGraph,
  };

  return <Column style={{ marginTop: 130 }} {...summaryConfig} />;
});
ExpensesSummary.displayName = 'ExpensesSummary';
