import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { Login } from './index';
import { LoginForm } from './LoginForm';
import { ForgotPassword } from './ForgotPassword';
import { ForgotPasswordComplete } from './ForgotPasswordComplete';
import { PasswordReset } from './PasswordReset';
import { PasswordUpdated } from './PasswordUpdated';
import { InviteLinkExpired } from './InviteLinkExpired';
import { CreatePassword } from './CreatePassword';
import { CreatePasswordComplete } from './CreatePasswordComplete';
import { Paths } from '../../types/paths';

export const loginRoutes = (authenticated: boolean, flexAnswerCustomer: boolean): RouteObject => ({
  path: Paths.login(),
  element: !authenticated ? (
    <Login />
  ) : (
    // FlexAnswer customers need to be authenticated first by navigating to knowledge, to access fAReports in analytics
    <Navigate to={flexAnswerCustomer ? '/knowledge' : '/analytics'} />
  ),
  children: [
    { index: true, element: <LoginForm /> },
    { path: Paths.loginRecover(), element: <ForgotPassword /> },
    { path: Paths.loginRecoverExpired(), element: <ForgotPassword expired={true} /> },
    { path: Paths.loginRecoverSuccess(), element: <ForgotPasswordComplete /> },
    { path: Paths.loginPasswordReset(), element: <PasswordReset /> },
    { path: Paths.loginPasswordResetSuccess(), element: <PasswordUpdated /> },
    { path: Paths.loginInviteExpired(), element: <InviteLinkExpired /> },
    { path: Paths.loginCreatePassword(), element: <CreatePassword /> },
    { path: Paths.loginCreatePasswordComplete(), element: <CreatePasswordComplete /> },
  ],
});
