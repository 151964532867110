import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { Content } from '../../../../../components/Content';
import { DataTable } from '../../../../../components/DataTable';
import { useDelete } from '../../../../../hooks/useDelete';
import { ActionButton } from '../../../../../components/ActionButton';
import { DeleteConfirmation } from '../../../../../components/DeleteConfirmation';
import { IconIndicator } from '../../../../../components/IconIndicator';
import { useIntegrations } from '../../../../../state/integrations';
import { FallbackConfigsService } from '../../../../../services/ai/fallback-configs';
import { AiIntegrationParams } from '../../../../../services/api';
import { Paths } from '../../../../../types/paths';

export const FallbackBehaviourList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { aiIntegration } = useIntegrations();
  const { data, isLoading } = useQuery(
    [FallbackConfigsService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      FallbackConfigsService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );
  const { mutate: deleteItem } = useDelete(
    [FallbackConfigsService.queryKey],
    FallbackConfigsService.remove,
  );

  const onCellHandler = ({ id }: { id: string }) => ({
    onClick: () => {
      navigate(Paths.aiFallbackBehaviourEdit({ id }));
    },
  });

  const handleDelete = React.useCallback(
    async id => aiIntegration && (await deleteItem({ id, apiData: aiIntegration })),
    [aiIntegration],
  );

  const columns: ColumnProps<any>[] = [
    {
      title: t('name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      onCell: onCellHandler,
      fixed: 'left',
      width: '12%',
    },
    {
      title: t('handoff'),
      dataIndex: 'escalate_message',
      onCell: onCellHandler,
      width: '33%',
    },
    {
      title: t('no_agents'),
      dataIndex: 'hangup_message',
      onCell: onCellHandler,

      width: '33%',
    },
    {
      title: t('raise_error_twilio'),
      dataIndex: 'powered_by_twilio',
      onCell: onCellHandler,
      width: '11%',
      render: record => <IconIndicator enabled={record} />,
    },
    {
      title: t('default'),
      dataIndex: 'is_default',
      onCell: onCellHandler,
      width: '8%',
      render: record => <IconIndicator enabled={record} />,
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
      render: ({ id, name }) => (
        <DeleteConfirmation
          id={id}
          onActionDelete={handleDelete}
          title={t('delete_fallback_behav')}
        >
          <Trans>
            {t('delete_description', {
              name,
            })}
          </Trans>
        </DeleteConfirmation>
      ),
    },
  ];

  const ButtonCreate = (
    <ActionButton type="create" onClick={() => navigate(Paths.aiFallbackBehaviourCreate())} />
  );

  return (
    <Content imgBg={false} Actions={[ButtonCreate]}>
      <DataTable dataSource={data} columns={columns} loading={isLoading} />
    </Content>
  );
};
