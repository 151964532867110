import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useAccount } from './account';
import { ProjectsService } from '../services/projects';
import { useAuth } from './auth';

export enum IntegrationType {
  ai,
  oldKnowledge,
  billing,
  knowledge,
  pulse,
}

type KnowledgeMeta = { VALink: string; JavascriptPlugin: string; FAQLink: string };
type UrlMeta = {
  AUTH_PROJECT_LIST: string;
  AUTH_TOKEN: string;
  PROJECT_ID: string;
};

export interface IProductIntegration {
  id: number;
  name: string;
  external_id: string;
  metadata: KnowledgeMeta | any;
  type: IntegrationType;
  urls: IIntegrationUrls[] | IAiIntegrationUrl[] | IBillingUrl[];
}
export interface IIntegrationUrls {
  name: string;
  url: string;
  metadata: any;
}
export interface IAiIntegrationUrl {
  name: string;
  url: string;
  metadata: UrlMeta;
}
export interface IChatUrl {
  name: string;
  url: string;
  metadata: {
    account_id: number;
    project_id: number | string;
  };
}

interface IKnowledgeDesign {
  id: number;
  name: string;
  external_id: string;
  metadata: KnowledgeMeta;
  type: IntegrationType;
  urls: IIntegrationUrls;
}
export interface IBillingUrl {
  name: string;
  url: string;
  metadata: {
    Authorization: string;
  };
}
export interface IKnowledgeUrl {
  externalId: string;
  url: string;
  metadata: UrlMeta;
  knowledgeItemMetadata: { is_approval_flow_enabled?: boolean };
}
interface IPulseData {
  id: number;
  name: string;
  external_id: string;
  metadata: {
    telcoAccount: string;
    telcoAuthToken: string;
  };
  type: IntegrationType;
  urls: IIntegrationUrls[];
}
interface IIntegrationContext {
  aiIntegrationClient?: IIntegrationUrls;
  aiIntegration?: IAiIntegrationUrl;
  chat?: IChatUrl;
  design?: IKnowledgeDesign[];
  oldKnowledge?: IKnowledgeDesign[];
  billing?: IBillingUrl;
  knowledge?: IKnowledgeUrl;
  pulse?: IPulseData;
  isFetching?: boolean;
}

export const IntegrationsContext = React.createContext<IIntegrationContext>(
  {} as IIntegrationContext,
);

export const IntegrationsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { activeAccountId, activeProjectId } = useAccount();
  const { authToken } = useAuth();
  const initialState = {
    aiIntegrationClient: undefined,
    aiIntegration: undefined,
    design: undefined,
    oldKnowledge: undefined,
    billing: undefined,
    knowledge: undefined,
    pulse: undefined,
  };
  const [state, setState] = useState<IIntegrationContext>(initialState);
  const { i18n } = useTranslation();
  const { data, isFetching, refetch } = useQuery<IProductIntegration[], string>(
    [ProjectsService.queryKeyIntegrations, activeAccountId, activeProjectId, i18n.language],
    ProjectsService.getIntegrations,
    { enabled: !!activeAccountId && !!activeProjectId },
  );

  useEffect(() => {
    if (!!activeAccountId && !!activeProjectId) {
      refetch();
    }
  }, [authToken]);

  useEffect(() => {
    setState(initialState);
    if (data?.length) {
      const automation = data.find(item => item.type === IntegrationType.ai);
      if (automation) {
        setState(prev => ({
          ...prev,
          aiIntegrationClient: automation.urls.find(item => item.name === 'manager_client'),
          aiIntegration: automation.urls.find(item => item.name === 'manager_api'),
          chat: automation.urls.find(item => item.name === 'chat_script_api'),
        }));
      }
      const oldKnowledgeItems = data.filter(item => item.type === IntegrationType.oldKnowledge);
      if (oldKnowledgeItems.length) {
        setState(prev => ({
          ...prev,
          oldKnowledge: getItemsByUrlName(oldKnowledgeItems, 'main_api'),
          design: getItemsByUrlName(oldKnowledgeItems, 'design_api'),
        }));
      }
      const billingItem = data.find(item => item.type === IntegrationType.billing);
      if (billingItem) {
        setState(prev => ({
          ...prev,
          billing: billingItem.urls.find(item => item.name === 'main_api'),
        }));
      }
      const knowledgeItem = data.find(item => item.type === IntegrationType.knowledge);
      if (knowledgeItem) {
        const mainData = knowledgeItem.urls.find(item => item.name === 'main_api');
        if (mainData) {
          setState(prev => ({
            ...prev,
            knowledge: {
              externalId: knowledgeItem.external_id,
              url: mainData.url,
              metadata: mainData.metadata,
              knowledgeItemMetadata: knowledgeItem.metadata,
            },
          }));
        }
      }
      const pulse = data.find(item => item.type === IntegrationType.pulse);
      if (pulse) {
        setState(prev => ({
          ...prev,
          pulse,
        }));
      }
    }
  }, [data]);

  const value = useMemo(
    () => ({
      aiIntegrationClient: state.aiIntegrationClient,
      aiIntegration: state.aiIntegration,
      chat: state.chat,
      oldKnowledge: state.oldKnowledge,
      billing: state.billing,
      design: state.design,
      knowledge: state.knowledge,
      pulse: state.pulse,
      isFetching,
    }),
    [state, isFetching],
  );

  return <IntegrationsContext.Provider value={value}>{children}</IntegrationsContext.Provider>;
};

export function useIntegrations(): IIntegrationContext {
  const context = useContext(IntegrationsContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}

const getItemsByUrlName = (
  knowledgeItems: IProductIntegration[],
  name: 'design_api' | 'main_api',
): IKnowledgeDesign[] => {
  return knowledgeItems.reduce((result: IKnowledgeDesign[], option) => {
    const designUrl = option.urls.find(url => url.name === name);
    if (designUrl) return result.concat({ ...option, urls: designUrl });
    return result;
  }, []);
};
