import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapseComponent } from '../../../../components/CollapseComponent';
import { AIAutomationBilling } from './AIAutomationBilling';
import { TitleWithToolTip } from '../../../../components/TitleWithToolTip';
import { theme } from '../../../../theme';
import { useFormContext } from '../../../../state/form';
import { getActiveKeys } from '../../../../utils/project';
import { billingFieldChecks } from '../../constants';
import { ProductType } from '../../types';

export const BillingDetails: React.FC = (): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { errorFields, isErrorsAffectingLayout } = useFormContext();
  const [activeKeys, setActiveKeys] = useState<ProductType[]>([]);

  const style = { marginBottom: isErrorsAffectingLayout ? -10 : -20 };

  useEffect(() => {
    setActiveKeys([...new Set([...activeKeys, ...getActiveKeys(errorFields, billingFieldChecks)])]);
  }, [errorFields]);

  const items = [
    {
      key: 'ai_n_a' as ProductType,
      label: <TitleWithToolTip title="AI & Automation" tooltip={t('tooltip_ai_n_a')} />,
      children: <AIAutomationBilling style={style} />,
      forceRender: true,
      style: { background: theme.lightBg, border: 'none' },
    },
  ];

  return <CollapseComponent items={items} activeKeys={activeKeys} setActiveKeys={setActiveKeys} />;
};
