import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron-double-right.svg';
import { StyledViewLink } from './ViewLink.styles';

export const ViewLink: React.FC<{
  to: string;
  children?: React.ReactNode;
}> = ({ to, children }) => {
  const { t } = useTranslation();
  return (
    <StyledViewLink to={to}>
      <ArrowIcon width={14} height={14} />
      <span>{children || t('view')}</span>
    </StyledViewLink>
  );
};
