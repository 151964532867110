import { Translation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Pulse } from './index';
import { CreateSchedule } from './Schedules/CreateSchedule';
import { SchedulesList } from './Schedules/SchedulesList';
import { EditSchedule } from './Schedules/EditSchedule';

export const pulseRoutes = () => ({
  path: Paths.pulse(),
  element: <Pulse />,
  breadcrumb: null,
  children: [
    { index: true, element: <Navigate replace to={Paths.pulseInsights()} />, breadcrumb: null },

    {
      path: Paths.pulseInsights(),
      breadcrumb: () => <Translation ns={'pages_title'}>{t => t('insights')}</Translation>,
    },
    {
      path: Paths.pulseSchedules(),
      element: <SchedulesList />,
      breadcrumb: () => <Translation ns={'pages_title'}>{t => t('schedules')}</Translation>,
    },
    {
      path: Paths.pulseScheduleCreate(),
      element: <CreateSchedule />,
      breadcrumb: () => <Translation>{t => t('create')}</Translation>,
    },
    {
      path: Paths.pulseScheduleEdit(),
      element: <EditSchedule />,
      breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
    },
  ],
});
