import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pie } from '@ant-design/plots';
import { IBillingAgentItems, IExpensesByAgentGroup } from '../../types';
import { chartDonut } from '../../../../utils/chartDonut';
import { PriceTable } from '../PriceTable';
import { getChartMeta } from '../../../../utils/billing';
import { CurrencySymbol } from '../../../../constants';

type Props = {
  data: IExpensesByAgentGroup[] | IBillingAgentItems[];
  currency: CurrencySymbol;
};

export const ExpensesChart: React.FC<Props> = React.memo(({ data, currency }) => {
  const { i18n } = useTranslation('billing');
  const locale = i18n.language;
  const meta = getChartMeta(currency, locale);
  const config = {
    data,
    color: data.map((item: IExpensesByAgentGroup) => item.color || ''),
    meta,
    ...chartDonut,
  };

  return config ? (
    <>
      <Pie {...config} legend={false} />
      <PriceTable currency={currency} locale={locale} expenses={data} />
    </>
  ) : null;
});
ExpensesChart.displayName = 'ExpensesChart';
