import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ReportsTable } from '../../../TableComponent/table';
import { ReportsService } from '../../../../../services/reports';
import { Reports } from '../../types';
import { Content } from '../../../../../components/Content';
import { useAccount } from '../../../../../state/account';
import { UppercaseLinkBold } from '../../../Analyics.styles';
import { useIntegrations } from '../../../../../state/integrations';
import { AiIntegrationParams } from '../../../../../services/api';

export const ExploreList: React.FC = () => {
  const { t } = useTranslation('reports');
  const [isFetching, setIsfething] = useState<boolean>(false);
  const { activeProjectId } = useAccount();
  const { aiIntegration } = useIntegrations();
  const { isLoading, data, refetch } = useQuery<Reports[]>(
    [ReportsService.queryKeyExplores, activeProjectId, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      ReportsService.getExplores({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  useEffect(() => {
    async function fetchExplores() {
      setIsfething(true);
      await refetch();
      setIsfething(false);
    }
    fetchExplores();
  }, [activeProjectId]);

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLinkBold to=""> {t('explore')}</UppercaseLinkBold>
      <ReportsTable loading={isLoading || isFetching} reports={data || []} type="explore" />
    </Content>
  );
};
