import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import useStateLoading from '../../hooks/useStateLoading';
import { BillingMenu } from './BillingMenu';
import { NoProject } from '../../components/NoProject';
import { AlertBanner } from '../../components/AlertBanner';

export const Billing: React.FC = () => {
  const { t } = useTranslation('billing');
  const { isStateLoading, isProject } = useStateLoading();

  if (!isProject && !isStateLoading) return <NoProject />;
  return (
    <>
      <BillingMenu />
      <AlertBanner message={t('billing_in_development')} type="info" />
      <Outlet />
    </>
  );
};
