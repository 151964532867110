import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formItemClass } from '../../../../theme';
import { AccountControl } from '../AccountControl';
import { CurrencySelector } from '../../../../components/CurrencySelector';

export const AccountInfo: React.FC<{ isEdit?: boolean }> = (): JSX.Element => {
  const { t } = useTranslation('account_settings');
  const { id } = useParams();
  const isEdit = !!id;

  return (
    <>
      <Form.Item
        name="sales_confirmation_number"
        label={t('sales_confirmation_id')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('sales_confirmation_id')} />
      </Form.Item>
      <Form.Item
        name="name"
        label={t('name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('name')} />
      </Form.Item>
      <Form.Item
        name={['account_info', 'currency']}
        label={t('currency')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <CurrencySelector placeholder={t('select_currency')} disabled={isEdit} />
      </Form.Item>
      {isEdit && <AccountControl />}
    </>
  );
};
