import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'antd';
import React from 'react';
import styled from '@emotion/styled';
import { useDropdown } from '../../../hooks/useDropdown';
import { DropdownMenu } from '../../Dropdown';
import { ReactComponent as CogIcon } from '../../../assets/icons/cog.svg';
import { useAccount } from '../../../state/account';

export const Settings: React.FC = () => {
  const { t } = useTranslation('header');
  const { visible, toggleDropdown, dropdownRef } = useDropdown<HTMLButtonElement>(false);
  const { activeAccountId, activeProjectId } = useAccount();

  const items = [
    {
      label: <Link to={`/account-settings/${activeAccountId}`}>{t('account_settings')}</Link>,
      key: 'account-settings',
    },
    {
      label: <Link to={`/projects/${activeProjectId}`}>{t('project_settings')}</Link>,
      key: 'project-settings',
    },
  ];

  return (
    <Dropdown
      menu={{ items, selectable: false, onClick: toggleDropdown }}
      dropdownRender={menu => <DropdownMenu.Root>{menu}</DropdownMenu.Root>}
      placement="bottomRight"
      trigger={['click']}
      open={visible}
      destroyPopupOnHide={true}
    >
      <StyledIcon
        ref={dropdownRef}
        id="settings-button"
        aria-controls="settings-menu"
        aria-haspopup="true"
        type="text"
        onClick={toggleDropdown}
        icon={<CogIcon width={16} height={16} />}
      ></StyledIcon>
    </Dropdown>
  );
};

const StyledIcon = styled(Button)`
  margin-left: 20px;
  color: ${({ theme }) => theme.primaryColor};
  &:not(:disabled):not(.ant-btn-disabled):not(.ant-upload-list-item-action):hover,
  &:not(:disabled):not(.ant-btn-disabled):not(.ant-upload-list-item-action):active,
  &:not(:disabled):not(.ant-btn-disabled):not(.ant-upload-list-item-action):focus {
    background-color: transparent;
    color: inherit;
  }
`;
