import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formItemClass } from '../../../../theme';
import { Space } from '../../../../components/Space';

export const CustomerInfo = (): JSX.Element => {
  const { t } = useTranslation('account_settings');
  const { id } = useParams();
  const isEdit = !!id;

  return (
    <>
      <Form.Item
        name={['customer_info', 'business_name']}
        label={t('business_name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('business_name')} />
      </Form.Item>
      <Space>
        <Form.Item
          name={['customer_info', 'company_number']}
          label={t('company_number')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('company_number')} />
        </Form.Item>
        <Form.Item
          name={['customer_info', 'vat_percent']}
          label={t('vat_percent')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <InputNumber min={0} placeholder={t('vat_percent')} disabled={isEdit} />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={['customer_info', 'email']}
          label={t('email')}
          className={formItemClass.base}
          rules={[{ type: 'email', required: true }]}
        >
          <Input placeholder={t('email')} />
        </Form.Item>
        <Form.Item
          name={['customer_info', 'contact_number']}
          label={t('contact_number')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('contact_number')} />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={['customer_info', 'address']}
          label={t('address')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('address')} />
        </Form.Item>
        <Form.Item
          name={['customer_info', 'town']}
          label={t('town')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('town')} />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name={['customer_info', 'country']}
          label={t('country')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('country')} />
        </Form.Item>
        <Form.Item
          name={['customer_info', 'post_code']}
          label={t('postal_code')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('postal_code')} />
        </Form.Item>
      </Space>
    </>
  );
};
