/**
 * Create a route that can be parsed using a typed-obj as param
 *
 * @example
 * Example using typed params
 * ```js
 * const userUpdate = route<'id'>('/user/:id')
 * userUpdate({ id: 1 }) // /user/1
 * ```
 */
export function route<P extends string = any>(path: string) {
  return function parse(params?: Record<P, any>, query?: Record<string, any>): string {
    const split = path.match(/[^/]+/g);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const parsed = split?.map(str => params?.[str.replace(':', '')] || str).join('/');

    return `/${parsed ?? ''}${searchStringify(query)}`;
  };
}

/**
 * Converts an object into a query string.
 *
 * @example
 * searchStringify({ foo: 'bar', baz: 'qux' });
 * // returns '?foo=bar&baz=qux'
 */
export function searchStringify(query?: Record<any, any>) {
  const qs = new URLSearchParams(query).toString();
  return qs.length ? `?${qs}` : '';
}
