import styled from '@emotion/styled';
import { Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { StyledAlert } from '../../components/Alert/Alert.styles';
import { DropdownMenu } from '../../components/Dropdown';

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.primaryColor};
  & > div {
    position: relative;
    flex: 0 0 50%;
    @media (max-width: 1024px) {
      flex: 0 0 100%;
    }
    &:first-of-type {
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }

  .ant-btn {
    height: 40px;
  }
`;

const Box = styled.div`
  width: 420px;
  padding: 60px 40px 40px;
  margin: 60px 0;
  background-color: #ffffff;
  font-family: ${({ theme }) => theme.fontFamilyAdditional};
  border-radius: 2px;
  .ant-form {
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    font-family: ${({ theme }) => theme.fontFamilyAdditional};
  }
  h2 {
    color: ${({ theme }) => theme.primaryColor};
    text-align: center;
  }
  .ant-form-item {
    font-family: ${({ theme }) => theme.fontFamilyBase};
  }
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 20px;
  padding: 0 40px;
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.typography.bodySmall.lineHeight};
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
`;

const PolicyLink = styled.a`
  display: inline-flex;
  align-items: center;
  font: inherit;
  text-decoration: underline;
  color: inherit;
  &:hover {
    text-decoration: underline;
    color: #fff;
  }
  & > span {
    margin-right: 4px;
  }
  & > svg {
    width: 12px;
    height: auto;
  }
`;

export const StyledLogin = {
  Root,
  Content,
  Box,
  Footer,
  PolicyLink,
};

export const ForgotPassword = styled(Link)`
  display: inline-block;
  margin-top: 10px;
  color: ${({ theme }) => theme.loginLinkColor};
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.typography.bodySmall.lineHeight};
  font-family: ${({ theme }) => theme.fontFamilyAccent};
  &:hover {
    color: ${({ theme }) => theme.loginLinkColor};
  }
`;

export const StyledLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 270px;

  p {
    margin-bottom: 20px;
  }

  & > div + div {
    margin-top: 20px;
  }

  ${StyledAlert.Root} {
    margin-top: 20px;
  }
`;

export const SelectLocalesDropdown = styled(Dropdown)`
  margin-top: 9px;
`;

export const LocalesDropdownMenu = styled(DropdownMenu.Root)`
  width: 150px;
  .ant-menu .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.primaryColor};
    color: #fff;
    font-family: ${({ theme }) => theme.fontFamilyAccent};
  }
`;

const LocaleButton = styled('button')`
  position: relative;
  border: 0 none;
  background-color: transparent;
  color: ${({ theme }) => theme.loginLinkColor};
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.typography.bodySmall.lineHeight};
  font-family: ${({ theme }) => theme.fontFamilyAccent};
  text-transform: uppercase;
`;
export const LocaleDropdownButton = styled(LocaleButton)`
  width: 60px;
  &::before {
    transform-origin: bottom;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.loginLinkColor};
    margin-top: -2px;
    transition: transform 0.3s ease;
  }
`;

export const ForgetPassWordLocaleDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
