import { IFieldCheck, IProductFeature } from './types';

const project_step_1_fields = ['name', 'project_logo', 'pulse', 'ai_n_a'];
const project_step_2_fields = [
  'ai_n_a_attributes.display_name',
  'ai_n_a_attributes.gcp_keyfile',
  'ai_n_a_attributes.gcp_project_name',
  'ai_n_a_attributes.gcp_fulfillment_auth_keyfile',
  'ai_n_a_attributes.twilio_account_sid',
  'ai_n_a_attributes.twilio_auth_token',
  'ai_n_a_attributes.twilio_phone_number',
  'default_language_id',
  'knowledge_v2_attributes.is_approval_flow_enabled',
  'pulse_attributes.telco_account_sid',
  'pulse_attributes.telco_auth_token',
];

const project_step_3_fields = [
  'billing_attributes.currency',
  'billing_attributes.platformFee',
  'billing_attributes.contractType',
  'billing_attributes.committedVolume',
  'billing_attributes.termMonths',
  'billing_attributes.overageInteractionsPrice',
  'billing_attributes.interactionsPrice',
  'billing_attributes.externalAnalyticsApiPrice',
  'billing_attributes.multilangualInteractionsPrice',
  'billing_attributes.supportServicePrice',
  'billing_attributes.optimisationPrice',
];
export const projectProducts: IProductFeature[] = [
  { label: 'AI & Automation', value: 'ai_n_a' },
  { label: 'Pulse', value: 'pulse' },
];
export const projectStepsFields = [
  project_step_1_fields,
  project_step_2_fields,
  project_step_3_fields,
];

export const settingsFieldChecks: IFieldCheck[] = [
  { names: ['pulse_attributes'], key: 'pulse' },
  { names: ['ai_n_a_attributes', 'default_language_id'], key: 'ai_n_a' },
];

export const billingFieldChecks: IFieldCheck[] = [{ names: ['billing_attributes'], key: 'ai_n_a' }];

export const bottomFieldsByWindowSize: {
  big: string[];
  small: string[];
} = {
  big: ['telco_account_sid', 'telco_auth_token', 'supportServicePrice', 'optimisationPrice'],
  small: ['telco_auth_token', 'optimisationPrice'],
};
